.bwd-service-card {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 84px;
  background-color: $white;
  border: 1px solid $grey-25;

  &__wrapper {
    display: flex;
    width: 100%;
    padding: 20px 16px;
  }

  &__icon {
    img {
      width: 40px;
      height: 40px;
    }
  }

  &__text {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #384044;
  }

  &__title {
    & > div.cmp-title {
      & > * {
        font-family: "Museo Sans";
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: start;
      }
    }
  }

  &__description {
    .cmp-text {
      font-family: "Museo Sans";
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  @media (min-width: 1430px) {
    width: 364px;
    min-height: 128px;

    &__icon {
      img {
        width: 50px;
        height: 50px;
      }
    }

    &__title {
      & > div.cmp-title {
        & > * {
          font-size: 17px;
        }
      }
    }

    &__text {
      width: 235px;
      margin-left: 16px;
      padding-left: 16px;
    }

    &__description {
      .cmp-text {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
