.bwd-tripadvisor-reviews {
  &__wrapper {
    &.swiper {
      padding: 15px;

      @include desktop {
        padding: 64px;
      }
    }
  }

  &__title {
    margin-bottom: 24px;

    .cmp-title {
      &__text {
        color: #0f1418;
      }
    }
  }

  &__subtitle {
    margin-bottom: 48px;
    font-weight: 400;
    text-align: center;
    color: #606669;
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    //margin-right: 16px;
    padding: 20px 16px 32px;
    width: 100%;
    height: 100%;
    max-width: 310px;
    border: 1px solid #d7d9da;
    border-radius: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__ratesblock {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &-logo {
      margin-right: 9px;
      width: 23px;
    }

    &-score {
      margin-right: 8px;
      font-weight: 600;
      color: $barcelo-black;
    }

    &-dots {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &-dot {
      margin-right: 2px;
      width: 12px;
      height: 12px;
      border: 1px solid #00a682;
      border-radius: 50%;

      &:last-of-type {
        margin-right: 0;
      }

      &.mod--filled {
        background-color: #00a682;
      }

      &.mod--half {
        background-image: linear-gradient(to right, #00a682 0%, #00a682 50%, #ffffff66 50%, #ffffff66 100%);
      }
    }
  }

  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    min-height: 35px;

    &-info {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-name {
      margin-left: 8px;
      font-size: 14px;
      line-height: 20px;
    }

    &-date {
      width: 120px;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
    }
  }

  &__info {
    min-height: 204px;

    &-title {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      color: $barcelo-black;
      @include ellipsis-multiline(20px, 28px, 1);
    }

    &-description {
      color: $barcelo-black;
      @include ellipsis-multiline(16px, 24px, 5);
    }
  }

  &__fullReview {
    position: absolute;
    bottom: 32px;
    display: flex;
    align-items: center;
    //margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-decoration: underline;
    color: $barcelo-black;

    &:hover {
      .bwd-tripadvisor-reviews__arrow {
        margin-left: 15px;
      }
    }
  }

  &__arrow {
    margin-top: 3px;
    margin-left: 11px;
    background-image: url("/content/dam/barcelo/commons/icons/ui/arrow_right.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 13px;
    height: 13px;
    transition: margin-left 0.2s ease-out;
  }

  &__modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-menu;
    width: 100%;
    height: 100%;
    background-color: #00000066;

    &-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 65px 25px;
      width: calc(100% - 30px);
      height: auto;
      max-height: 550px;
      border-radius: 16px;
      overflow: scroll;
      background-color: #ffffff;

      @include desktop {
        width: 100%;
        max-width: 600px;
        max-height: 600px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-image: linear-gradient(to top, #30a2bf, #2f5a76);
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background-color: #2f5a76;
          background-image: initial;
        }
      }
    }

    &-close {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 12px;
      height: 12px;
      background-image: url("/content/dam/barcelo/commons/icons/ui/close.svg");
      background-size: contain;
      cursor: pointer;
    }

    &.mod--open {
      display: block;

      .bwd-tripadvisor-reviews__modal-box {
        animation: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards openTripadvisorModal;
      }
    }
  }

  .swiper {
    &-wrapper {
      //margin: auto;
      max-width: 1312px;
      cursor: grab;

      &.mod--center {
        margin: auto;
        max-width: fit-content;

        @include desktop {
          cursor: default;
        }
      }
    }

    &-slide {
      width: auto;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

// Modifiers
.mod--disable-scroll {
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

// Animations
@keyframes openTripadvisorModal {
  from {
    opacity: 0;
    transform: scale(0.9) translate(-50%, -50%);
  }

  to {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}
