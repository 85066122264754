.bwd-launcher {
  &.mod--two-inline {
    .bwd-launcher__wrapper {
      position: relative;
      .image {
        height: 300px;
      }

      &-cta {
        width: calc(100% - 96px);
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        border-top: 2px solid #fff;
        z-index: $z-overground;

        &__link {
          font-family: "Museo Sans";
          font-size: 24px;
          font-weight: 600;
          line-height: 42px;
          letter-spacing: 0.0025em;
          text-align: left;
          text-decoration: none;
          color: #fff;
          padding-top: 11px;
        }

        &__icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          font-size: 24px;
          font-weight: 600;
          line-height: 42px;
          letter-spacing: 0.0025em;
          text-align: right;
          text-decoration: none;
          color: #fff;
          padding-top: 11px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 16.07%, rgba(0, 0, 0, 0.5) 83.62%);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.mod--three-inline {
    width: 78.5%;
    flex-shrink: 0;
    .bwd-launcher__wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 100%;
      .image {
        width: 80px;
        min-width: 80px;
        height: 100%;
      }
      .bwd-launcher-content {
        background-color: #fff;
        padding: 12px 16px;

        &__label {
          font-family: "Sfizia";
          font-size: 28px;
          font-style: italic;
          font-weight: 400;
          line-height: 28px;
          color: #384044;
          margin-bottom: 8px;
        }

        &__description {
          font-family: "Museo Sans";
          font-size: 16px;
          font-weight: 300;
          line-height: 19.2px;
          letter-spacing: 0.005em;
          color: #384044;
        }
      }
    }
  }

  &.mod--three-inline-abstract {
    width: 240px;
    flex-shrink: 0;
    &:last-of-type {
      margin-right: 24px;
    }
    .bwd-launcher__wrapper {
      position: relative;

      .image {
        height: 321px;
      }
      .bwd-launcher-content {
        &__label {
          font-family: "Sfizia";
          font-size: 26px;
          font-style: italic;
          font-weight: 400;
          line-height: 26px;
          color: #384044;
          padding: 24px;
          text-align: center;
        }

        &__description {
          display: none;
        }
      }
    }
  }

  &.mod--three-right-side {
    .bwd-launcher__wrapper {
      display: flex;
      flex-direction: row;
      gap: 16px;
      &-img {
        .image {
          height: 132px;
          width: 132px;
        }
      }
      .bwd-launcher-content {
        &__label {
          font-family: "Sfizia";
          font-size: 40px;
          font-style: italic;
          font-weight: 400;
          line-height: 64px;
          color: #384044;
          margin-bottom: 8px;
        }

        &__description {
          font-family: "Museo Sans";
          font-size: 16px;
          font-weight: 300;
          line-height: 19.2px;
          letter-spacing: 0.005em;
          color: #384044;
        }
      }
    }
  }

  &.mod--four-inline {
    min-width: 178px;
    max-width: 257px;
    flex-shrink: 0;
    &:last-of-type {
      margin-right: 24px;
    }
    .bwd-launcher__wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      .image {
        height: 490px;
        width: 100%;
      }

      &-info {
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: $z-overground;

        &__text {
          font-family: "Sfizia";
          font-style: italic;
          font-size: 19.6px;
          font-weight: 300;
          line-height: 28px;
          letter-spacing: 0.0015em;
          text-align: center;
          color: #ffffff;
        }

        &__text-2 {
          display: none;
        }
        &__icon {
          width: 32px;
          height: 32px;
          font-size: 32px;
          font-weight: 300;
          line-height: 28px;
          color: #ffffff;
          margin-top: 10px;
          display: inline-block;
        }
      }
      &::after {
        content: "";
        width: 100%;
        height: 220px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(65, 75, 85, 0) 20%, rgba(65, 75, 85, 0.6) 50%, rgba(65, 75, 85, 0.8) 70%, rgba(65, 75, 85, 1) 90%);
        transition: opacity 0.5s ease;
        opacity: 1;
      }
    }
  }

  &.mod--six-inline {
    min-width: 178px;
    width: 178px;
    flex-shrink: 0;
    &:last-of-type {
      margin-right: 24px;
    }
    .bwd-launcher__wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      .image {
        height: 490px;
        width: 100%;
      }

      &-info {
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: $z-overground;

        &__text {
          font-family: "Sfizia";
          font-style: italic;
          font-size: 19.6px;
          font-weight: 300;
          line-height: 28px;
          letter-spacing: 0.0015em;
          text-align: center;
          color: #ffffff;
        }

        &__text-2 {
          display: none;
        }
        &__icon {
          width: 32px;
          height: 32px;
          font-size: 32px;
          font-weight: 300;
          line-height: 28px;
          color: #ffffff;
          margin-top: 10px;
          display: inline-block;
        }
      }
      &::after {
        content: "";
        width: 100%;
        height: 220px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(65, 75, 85, 0) 20%, rgba(65, 75, 85, 0.6) 50%, rgba(65, 75, 85, 0.8) 70%, rgba(65, 75, 85, 1) 90%);
        transition: opacity 0.5s ease;
        opacity: 1;
      }
    }
  }

  &.mod--six-over-two-lines {
    width: 100%;

    a {
      text-decoration: underline;
    }

    .bwd-launcher__wrapper {
      display: flex;
      flex-direction: column;
      &-carousel {
        height: 271px;
        .swiper-wrapper {
          height: 271px;
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }

        .swiper-pagination {
          &-bullets {
            bottom: 15px !important;
          }

          &-bullet {
            background-color: #fff !important;
            height: 6px !important;
            border-radius: 100px !important;
            margin: 0 4px !important;
            border: initial !important;
            &-active {
              width: 20px !important;
            }
          }
        }
      }

      .bwd-launcher-content {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        padding: 0;

        &__label {
          font-family: "Sfizia";
          font-size: 40px;
          font-style: italic;
          font-weight: 400;
          line-height: 46px;
          padding: 16px 0px 0px;
        }

        &__description {
          display: block;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: 0.005em;
          color: #384044;
        }
      }
    }
  }
}

@media (min-width: $desktop-min) {
  .bwd-launcher {
    &.mod--two-inline {
      .bwd-launcher__wrapper {
        position: relative;
        .image {
          height: 644px;
        }

        &-cta {
          width: calc(100% - 96px);
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 48px;
          left: 50%;
          transform: translateX(-50%);
          border-top: 2px solid #fff;
          z-index: $z-overground;

          &__link {
            font-family: "Museo Sans";
            font-size: 36px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.0025em;
            text-align: left;
            text-decoration: none;
            color: #fff;
            padding-top: 11px;
          }

          &__icon {
            display: inline-block;
            width: 64px;
            height: 64px;
            font-size: 36px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.0025em;
            text-align: right;
            text-decoration: none;
            color: #fff;
            padding-top: 11px;
          }
        }
      }
    }
    &.mod--three-inline {
      width: 33%;
      flex-shrink: initial;
      .bwd-launcher__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        .image {
          height: 606px;
          max-width: initial;
          width: initial;
          min-width: initial;
        }

        .image:hover + .bwd-launcher-content .bwd-launcher-content__description {
          opacity: 1;
        }

        .bwd-launcher-content {
          display: flex;
          flex-direction: column-reverse;
          background-color: transparent;
          padding: 0;

          &__label {
            font-family: "Sfizia";
            font-size: 40px;
            font-style: italic;
            font-weight: 400;
            line-height: 64px;
            text-align: center;
            margin-top: 24px;
            margin-bottom: 0px;
          }

          &__description {
            width: calc(100% - 48px);
            position: absolute;
            opacity: 0;
            left: 50%;
            transform: translateX(-50%);
            bottom: 131px;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #fff;
            transition: all 200ms ease-in-out;

            &::after {
              content: "";
              width: calc(100% + 48px);
              height: 420px;
              position: absolute;
              bottom: -43px;
              left: -24px;
              background: linear-gradient(180deg, rgba(43, 53, 61, 0) 20%, rgba(43, 53, 61, 0.7) 50%, rgba(43, 53, 61, 0.9) 90%);
              z-index: $z-underground;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    &.mod--three-inline-abstract {
      width: 33%;
      flex-shrink: initial;
      &:last-of-type {
        margin-right: 0px;
      }
      .bwd-launcher__wrapper {
        display: flex;
        flex-direction: column;
        &-img {
          height: 606px;
          max-width: initial;
          overflow: hidden;
          .image {
            height: 100%;
            width: 100%;
            transition: all 1.2s ease-in-out;

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        .bwd-launcher-content {
          display: flex;
          flex-direction: column;
          background-color: transparent;
          padding: 0;

          &__label {
            font-family: "Sfizia";
            font-size: 40px;
            font-style: italic;
            font-weight: 400;
            line-height: 46px;
            text-align: center;
            padding: 24px 24px 8px;
            margin-bottom: 0px;
          }

          &__description {
            display: block;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.005em;
            color: #384044;
          }
        }
      }
    }

    &.mod--three-right-side {
      .bwd-launcher__wrapper {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .bwd-launcher-content {
          &__label {
            font-family: "Sfizia";
            font-size: 40px;
            font-style: italic;
            font-weight: 400;
            line-height: 64px;
            color: #384044;
            margin-bottom: 8px;
          }

          &__description {
            font-family: "Museo Sans";
            font-size: 16px;
            font-weight: 300;
            line-height: 19.2px;
            letter-spacing: 0.005em;
            color: #384044;
          }
        }
      }
    }

    &.mod--four-inline {
      width: 310px;
      max-width: unset;
      min-width: none;
      flex-shrink: initial;
      &:last-of-type {
        margin-right: 0px;
      }
      .bwd-launcher__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        .image {
          height: 525px;
          max-width: initial;
        }

        &-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          bottom: 21px;
          left: 50%;
          transform: translateX(-50%);
          z-index: $z-overground;

          &__text,
          &__text-2 {
            display: block;
            font-family: "Museo Sans";
            font-style: normal;
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
            letter-spacing: 0.0015em;
            text-align: center;
            color: #ffffff;
            transition: all 0.8s ease;
            transform: translateY(22px);
          }

          &__text-2 {
            font-family: "Sfizia";
            font-style: italic;
            position: absolute;
            font-size: 19.6px;
            bottom: 41px;
            opacity: 0;
          }

          &__icon {
            width: 32px;
            height: 32px;
            font-size: 32px;
            font-weight: 300;
            line-height: 28px;
            color: #ffffff;
            margin-top: 10px;
            transition: opacity 0.8s ease;
            opacity: 0;
            display: inline-block;
          }
        }
        &::after {
          content: "";
          width: 100%;
          height: 220px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(180deg, rgba(65, 75, 85, 0) 20%, rgba(65, 75, 85, 0.6) 50%, rgba(65, 75, 85, 0.8) 70%, rgba(65, 75, 85, 1) 90%);
          transition: opacity 0.5s ease;
          opacity: 1;
        }
        &:hover {
          &::after {
            content: "";
            opacity: 1;
            background: linear-gradient(180deg, rgba(43, 53, 61, 0) 20%, rgba(43, 53, 61, 0.7) 50%, rgba(43, 53, 61, 0.9) 90%);
          }
          .bwd-launcher__wrapper-info {
            &__text {
              opacity: 0;
              transform: translateY(-8px) scale(1.3);
            }

            &__text-2 {
              display: inline-block;
              transform: translateY(-2px);
              opacity: 1;
            }

            &__icon {
              opacity: 1;
            }
          }
        }
      }
    }
    &.mod--six-inline {
      width: 190px;
      min-width: unset;
      flex-shrink: initial;
      &:last-of-type {
        margin-right: 0px;
      }
      .bwd-launcher__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        .image {
          height: 525px;
          max-width: initial;
        }

        &-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          bottom: 21px;
          left: 50%;
          transform: translateX(-50%);
          z-index: $z-overground;

          &__text,
          &__text-2 {
            display: block;
            font-family: "Museo Sans";
            font-style: normal;
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
            letter-spacing: 0.0015em;
            text-align: center;
            color: #ffffff;
            transition: all 0.8s ease;
            transform: translateY(22px);
          }

          &__text-2 {
            font-family: "Sfizia";
            font-style: italic;
            font-size: 19.6px;
            position: absolute;
            bottom: 41px;
            opacity: 0;
          }

          &__icon {
            width: 32px;
            height: 32px;
            font-size: 32px;
            font-weight: 300;
            line-height: 28px;
            color: #ffffff;
            margin-top: 10px;
            transition: opacity 0.8s ease;
            opacity: 0;
            display: inline-block;
          }
        }
        &::after {
          content: "";
          width: 100%;
          height: 220px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(180deg, rgba(65, 75, 85, 0) 20%, rgba(65, 75, 85, 0.6) 50%, rgba(65, 75, 85, 0.8) 70%, rgba(65, 75, 85, 1) 90%);
          transition: opacity 0.5s ease;
          opacity: 1;
        }
        &:hover {
          &::after {
            content: "";
            opacity: 1;
            background: linear-gradient(180deg, rgba(43, 53, 61, 0) 20%, rgba(43, 53, 61, 0.7) 50%, rgba(43, 53, 61, 0.9) 90%);
          }
          .bwd-launcher__wrapper-info {
            &__text {
              opacity: 0;
              transform: translateY(-8px) scale(1.3);
            }

            &__text-2 {
              display: inline-block;
              transform: translateY(-2px);
              opacity: 1;
            }

            &__icon {
              // display: inline-block;
              opacity: 1;
            }
          }
        }
      }
    }

    &.mod--six-over-two-lines {
      width: calc(33% - 16px);
      .bwd-launcher__wrapper {
        display: flex;
        flex-direction: column;
        &-carousel {
          height: 271px;
          .swiper-wrapper {
            height: 271px;
          }

          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }

          .swiper-pagination {
            &-bullets {
              bottom: 15px !important;
            }

            &-bullet {
              background-color: #fff !important;
              height: 6px !important;
              border-radius: 100px !important;
              margin: 0 4px !important;
              border: initial !important;
              &-active {
                width: 20px !important;
              }
            }
          }
        }

        .bwd-launcher-content {
          display: flex;
          flex-direction: column;
          background-color: transparent;
          padding: 0;

          &__label {
            font-family: "Sfizia";
            font-size: 40px;
            font-style: italic;
            font-weight: 400;
            line-height: 46px;
            padding: 16px 0px 0px;
          }

          &__description {
            display: block;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #384044;
          }
        }
      }
    }
  }
}
