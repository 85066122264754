/* FONTS */
@font-face {
  font-family: "Sfizia";
  src: url("../resources/fonts/sfizia/sfizia-regular.woff2") format("woff2");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sfizia";
  src: url("../resources/fonts/sfizia/sfizia-bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sfizia";
  src: url("../resources/fonts/sfizia/sfizia-regular-italic.woff2") format("woff2");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Sfizia";
  src: url("../resources/fonts/sfizia/sfizia-bold-italic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
