.bwd-slide {
  height: 100%;

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-overground;
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, #00000000, #00000033), radial-gradient(#0000004d, #00000000);
    }

    .image {
      height: 100%;
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $z-mask;
    transform: translate(-50%, -50%);
    color: #ffffff;

    .cmp-title {
      &__text {
        font-family: "Sfizia";
        font-size: 36px;
        line-height: 44px;
        font-weight: 400;
        text-align: center;
        font-style: italic;
      }
    }
  }
}
