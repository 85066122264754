.bwd-title {
  .cmp-title {
    &__text {
      font-family: "Sfizia";
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      letter-spacing: 0.4em;
      margin: unset;
    }

    &__subtitle,
    &__richtext {
      font-family: "Sfizia";
      font-size: 38px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.005em;

      @include desktop {
        font-size: 64px;
        line-height: 64px;
        letter-spacing: 0;
      }
    }

    &__text,
    &__subtitle,
    &__richtext {
      display: block;
    }

    &__subtitle {
      margin-top: 24px;
    }

    &__richtext {
      margin: unset;
    }
  }

  //POLICIES
  &.mod--align-left {
    .cmp-title {
      text-align: left;
    }
  }
}
