.bwd-menu {
  &__wrapper {
    max-width: 1440px;
    margin: 45px auto 24px;
    padding: 0 24px;

    @include desktop {
      padding: 0;
    }

    &__content {
      display: flex;
      justify-content: center;
    }
  }
  &__links {
    display: inline-flex;
    border-bottom: 1px solid #e0e6ea;
    overflow-x: auto;
    gap: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    @include desktop {
      overflow-x: initial;
    }

    &-link {
      position: relative;
      font-family: "Museo Sans";
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: center;
      padding: 8px;
      flex-shrink: 0;
      cursor: pointer;

      @include desktop {
        flex-shrink: unset;
      }

      &:hover {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          opacity: 0.5;
          border: 1px solid #4ba8bf;
        }
      }

      &.mod--selected {
        font-weight: 400;
        &::after {
          content: "";
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          border: 1px solid #30a2bf;
        }
      }
    }
  }
}
