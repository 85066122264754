/*  MOBILE FIRST (~ TABLET) */
.c-bwd-teaser {
  //#### teaser version 1 (default)
  position: relative;
  display: flex;
  flex-flow: column;

  &__img {
    height: 320px;
  }

  &__card {
    &__container {
      display: flex;
      justify-content: center;
      flex-flow: column;
      height: 100%;
      padding-top: 32px;
      padding-left: 24px;
      padding-right: 16px;
      padding-bottom: 60px;

      > .title {
        font-family: "Sfizia";
        font-weight: 400;
        padding-bottom: 0;

        .cmp-title {
          text-align: left;

          &__text {
            line-height: 19px;
            padding-bottom: 24px;
          }

          &__subtitle {
            font-size: 40px;
            line-height: 40px;
            margin-top: 0;
            padding-bottom: 16px;
          }
        }
      }

      > .pre-txt {
        padding-bottom: 24px;
        display: none;
      }

      > .txt {
        display: flex;
        padding-bottom: 24px;

        > .txt-line {
          width: calc(24px + 9px);
          padding-right: 9px;
          padding-top: 9px;

          &::before {
            content: "";
            width: 24px;
            height: 1px;
            background-color: #c5c5c5;
            display: block;
          }
        }

        > .txt-parag {
          max-width: 304px;
        }
      }

      > .cta {
        margin-left: calc(24px + 9px);
      }
    }
  }
}

//#### teaser config 2
.v2.c-bwd-teaser {
  flex-flow: column-reverse;
}

//####

//#### teaser config 3
.v3.c-bwd-teaser {
  .c-bwd-teaser {
    &__img {
      height: 671px;

      > .cmp-image {
        height: 55%;
      }

      &::before {
        content: "";
        position: absolute;
        top: 25%;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(0, 2, 11, 0) 21%, rgb(5, 10, 19) 45%);
        height: 75%;
        z-index: 1;
      }
    }

    &__card {
      z-index: 2;
      position: absolute;
      width: calc(100% - 48px);
      min-height: 312px;
      margin-left: 24px;
      background-color: #f5f5f6;
      padding: 40px 24px 40px 24px;
      bottom: 48px;
      text-align: center;

      &__container {
        padding: 0;

        > .title {
          padding-bottom: 0;

          .cmp-title {
            text-align: center;

            &__text {
              display: none;
            }
          }
        }

        > .pre-txt {
          display: none;
        }

        > .txt {
          padding-bottom: 16px;

          > .txt-line {
            display: none;
          }

          > .txt-parag {
            max-width: 100%;
          }
        }

        > .cta {
          margin-left: 0;
        }
      }
    }

    &__img {
      width: 100%;
    }
  }
}

//####

//#### teaser config 4
.v4.c-bwd-teaser {
  padding: 0;
  align-items: center;

  .c-bwd-teaser {
    &__card {
      background-color: transparent;
      position: absolute;
      text-align: center;
      padding-left: 16px;
      padding-right: 16px;
      top: 0;
      bottom: 0;
      color: white;

      &__container {
        padding: 0;

        > .title {
          .cmp-title {
            text-align: center;
          }
        }

        > .pre-txt {
          display: flex;
          justify-content: center;

          > .icon-poi {
            padding-top: 6px;
            padding-right: 6px;
          }

          a {
            text-decoration: underline;
          }
        }

        > .txt {
          padding-bottom: 72px;

          &.txt__pb0 {
            padding-bottom: 0;
          }

          > .txt-line {
            display: none;
          }

          > .txt-parag {
            max-width: 100%;
          }
        }

        > .cta {
          margin-left: 0;
        }
      }

      &.card__mw0 {
        max-width: none;
      }
    }

    &__img {
      height: 671px;
      width: 100%;
      background-color: black;

      .cmp-image {
        opacity: 0.6;
      }
    }
  }
}

//####

//#### teaser config 5
.v5.c-bwd-teaser {
  .c-bwd-teaser {
    &__card {
      &__container {
        padding: 16px 20px;

        > .pre-txt,
        > .cta {
          margin-top: 20px;
          margin-left: 0;
        }

        > .cta {
          text-align: center;
        }

        > .title {
          .cmp-title {
            &__text {
              display: none;
            }
          }
        }

        > .txt {
          padding-bottom: 0;
          color: #606669;

          > .txt-line {
            display: none;
          }

          > .txt-parag {
            max-width: 100%;
          }
        }
      }
    }

    &__img {
      height: 495px;
      width: 100%;
    }
  }
}

//####

//#### teaser config 6
.v6.c-bwd-teaser {
  padding: 0;
  align-items: center;

  .c-bwd-teaser {
    &__card {
      background-color: transparent;
      position: absolute;
      text-align: center;
      padding-left: 16px;
      padding-right: 16px;
      top: 0;
      bottom: 0;
      color: white;

      &__container {
        padding: 0;

        > .title {
          font-size: 54px;
          line-height: 54px;

          .cmp-title {
            text-align: center;

            &__subtitle {
              font-size: 54px;
              line-height: 54px;
            }
          }
        }

        > .pre-txt {
          display: none;
        }

        > .txt {
          padding-bottom: 24px;

          > .txt-line {
            display: none;
          }

          > .txt-parag {
            max-width: 100%;
          }
        }

        > .cta {
          margin-left: 0;
        }
      }
    }

    &__img {
      height: 549px;
      width: 100%;
      background-color: black;

      .cmp-image {
        opacity: 0.6;
      }
    }
  }
}

//####

//#### teaser config 7
.v7.c-bwd-teaser {
  .c-bwd-teaser {
    &__card {
      &__container {
        > .pre-txt {
          display: none;
        }

        > .title {
          .cmp-title {
            &__text {
              display: none;
            }
          }
        }

        > .txt {
          > .txt-line {
            display: none;
          }

          > .txt-parag {
            max-width: 100%;
          }
        }

        > .cta {
          display: none;
        }
      }
    }
  }
}

//####

//#### teaser config 8
.v8.c-bwd-teaser {
  padding: 0;
  align-items: center;

  .c-bwd-teaser {
    &__card {
      background-color: white;
      opacity: 0.8;
      position: absolute;
      text-align: center;
      padding: 40px 24px;
      margin-right: 24px;
      margin-left: 24px;
      bottom: 48px;

      &__container {
        padding: 0;

        > .pre-txt,
        > .cta {
          display: none;
        }

        > .title {
          font-size: 40px;
          line-height: 40px;

          .cmp-title {
            text-align: center;

            &__text {
              display: none;
            }
          }
        }

        > .txt {
          padding-bottom: 0;

          > .txt-line {
            display: none;
          }

          > .txt-parag {
            max-width: 100%;
          }
        }
      }
    }

    &__img {
      height: 495px;
      width: 100%;
    }
  }
}

//####

/* DESKTOP */

@include desktop {
  .c-bwd-teaser {
    //#### teaser version 1 (default)
    max-width: 1440px;
    margin: auto;
    padding: 48px 64px;
    display: flex;
    flex-flow: row;

    &__img {
      height: 644px;
      width: 50%;
    }

    &__card {
      padding-left: 48px;
      width: 50%;

      &__container {
        padding: 0;

        > .title {
          .cmp-title {
            &__subtitle {
              padding-bottom: 24px;
              font-size: 64px;
              line-height: 64px;
            }
          }
        }

        > .txt {
          padding-bottom: 24px;

          > .txt-line {
            width: calc(64px + 48px + 24px);
            padding-left: 48px;
            padding-right: 24px;
            padding-top: 0px;

            &::before {
              width: 64px;
            }
          }

          > .txt-parag {
            max-width: 304px;
          }
        }

        > .cta {
          margin-left: calc(64px + 48px + 24px);
        }
      }
    }
  }

  //#### teaser config 1 only animation
  .v1.c-bwd-teaser {
    .c-bwd-teaser {
      &__img {
        .cmp-image__image {
          transition: all 1.2s ease-in-out;
        }

        &:hover {
          .cmp-image__image {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  //####

  //#### teaser config 2
  .v2.c-bwd-teaser {
    flex-flow: row-reverse;

    .c-bwd-teaser {
      &__img {
        .cmp-image__image {
          transition: all 1.2s ease-in-out;
        }

        &:hover {
          .cmp-image__image {
            transform: scale(1.1);
          }
        }
      }

      &__card {
        padding-left: 0;
        padding-right: 48px;
      }
    }
  }

  //####

  //#### teaser config 3
  .v3.c-bwd-teaser {
    padding-right: 0;
    padding-left: 222px;

    .c-bwd-teaser {
      &__card {
        transition: all 1.2s ease-in-out;
        width: 594px;
        text-align: left;
        padding: 86px 89px 86px 64px;
        bottom: auto;
        margin-top: 77px;
        left: 0;
        margin-left: -32px;

        &__container {
          > .title {
            .cmp-title {
              text-align: left;

              &__subtitle {
                padding-bottom: 16px;
              }
            }

            &.smaller {
              .cmp-title {
                &__subtitle {
                  font-size: 52px;
                  line-height: 56px;
                }
              }
            }
          }

          > .txt {
            padding-bottom: 60px;
          }
        }
      }

      &__img {
        width: 100%;
        height: 654px;

        .cmp-image__image {
          transition: all 1.2s ease-in-out;
        }

        > .cmp-image {
          height: 100%;
        }

        &::before {
          display: none;
        }
      }
    }

    &:hover {
      .c-bwd-teaser {
        &__img {
          .cmp-image__image {
            transform: scale(1.1);
          }
        }

        &__card {
          margin-left: 0;
        }
      }
    }
  }

  //####

  //#### teaser config 4
  .v4.c-bwd-teaser {
    max-width: none;
    flex-flow: column;

    .c-bwd-teaser {
      &__card {
        max-width: 716px;
        margin-top: 119px;
        top: auto;
        bottom: auto;
      }

      &__img {
        height: 549px;
      }
    }
  }

  //####
  //#### teaser config 5
  .v5.c-bwd-teaser {
    flex-flow: column;

    .c-bwd-teaser {
      padding-bottom: 24px;
      padding-top: 24px;

      &__card {
        padding-left: 0;
        width: 100%;

        &__container {
          padding-bottom: 0;
          padding-left: 0;
          padding-top: 24px;

          > .title,
          > .txt {
            max-width: 867px;
          }

          > .title {
            font-size: 40px;
            line-height: 64px;
            padding-bottom: 0;

            .cmp-title {
              &__subtitle {
                font-size: 40px;
                line-height: 64px;
                padding-bottom: 24px;
              }
            }
          }

          > .txt {
            padding-left: 0;

            > .txt-line {
              display: none;
            }

            > .txt-parag {
              max-width: 100%;
            }
          }

          > .cta {
            margin-top: 40px;
          }
        }
      }

      &__img {
        height: 618px;
        width: 100%;
      }
    }
  }

  //####

  //#### teaser config 6
  .v6.c-bwd-teaser {
    max-width: none;
    flex-flow: column;
    justify-content: center;

    .c-bwd-teaser {
      &__card {
        top: auto;
        bottom: auto;

        &__container {
          > .title {
            .cmp-title {
              &__subtitle {
                font-size: 64px;
                line-height: 64px;
              }
            }
          }

          > .txt {
            margin: auto;

            > .txt-parag {
              max-width: 716px;
            }
          }
        }
      }

      &__img {
        height: 549px;
      }
    }
  }

  //####

  //#### teaser config 7
  .v7.c-bwd-teaser {
    //padding: 0;
    //align-items: center;
    .c-bwd-teaser {
      &__card {
        &__container {
          > .txt {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  //####

  //#### teaser config 8
  .v8.c-bwd-teaser {
    padding: 24px 64px 64px 64px;
    //height: 741px;
    //align-items: center;
    justify-content: center;
    flex-flow: row;
    flex-wrap: wrap;

    .c-bwd-teaser {
      &__card {
        width: 613px;
        bottom: auto;
        margin: 0;
        align-self: flex-end;
        margin-bottom: 56px;
        padding-bottom: 24px;

        &__container {
          > .title {
            .cmp-title {
              text-align: center;

              &__subtitle {
                font-size: 40px;
                line-height: 40px;
              }
            }
          }

          > .txt {
            > .txt-parag {
              max-width: 553px;
            }
          }
        }
      }

      &__img {
        height: 669px;
        width: 100%;
      }
    }
  }

  //####
}
