.bwd-dest-comparator {
  .c-hotels-comparator {
    padding: 80px 24px;
    @include desktop {
      display: flex;
      flex-direction: row;
      gap: clamp(0px, calc(100vw - 1030px), 64px);
      padding: 40px 92px 80px 64px;
      max-width: 1440px;
      margin: 0 auto;
    }

    &__sidebar {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      transition: 0.5s ease-out;
      left: -102%;
      overflow: auto;
      z-index: 3;
      padding: 72px 16px 100px;
      background: #ffff;

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        border-radius: 5px;
        margin-top: 15px;
      }

      &::-webkit-scrollbar-track {
        background: #eaeef1;
      }

      &::-webkit-scrollbar-thumb {
        background: #30a2bf;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #30a2bf;
      }

      @include desktop {
        width: 20%;
        min-width: 240px;
        position: initial;
        overflow: initial;
        padding: 0;
      }

      .sidebar__header {
        display: none;
        gap: 8px;
        margin-bottom: 25px;
        align-items: center;

        @include desktop {
          display: flex;
          border-bottom: 1px solid #d7d9da;
          margin-bottom: 32px;
          padding: 8px;
          pointer-events: none;
        }
        .sidebar__title {
          font-family: "Museo Sans";
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
          text-align: left;
          color: #384044;

          @include desktop {
            font-size: 20px;
            line-height: 32px;
            color: #384044;
          }
        }
        .sidebar__close-btn {
          display: none;
        }
      }

      .sidebar__facets-list {
        @include desktop {
          display: block;
          position: initial;
          z-index: 0;
          padding: 0;
        }

        .accordion-item {
          .accordion-toggle {
            font-family: "Museo Sans";
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0.0015em;
            text-align: left;
            color: #384044;
            margin-bottom: 0px;

            .svg-icon {
              transform: rotate(90deg);
              height: 5px;
              width: 11px;
            }
          }

          &.active {
            .accordion-toggle {
              .svg-icon {
                transform: rotate(270deg);
              }
            }

            .accordion-content {
              margin-top: 19px;
              margin-bottom: 32px;

              &::after {
                content: "";
                opacity: 0;
              }
            }

            &::after {
              content: "";
              opacity: 0;
            }
          }

          .accordion-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            label {
              display: flex;
              align-items: center;
              gap: 12px;
              font-family: "Museo Sans";
              font-size: 16px;
              font-weight: 300;
              line-height: 24px;
              letter-spacing: 0.005em;
              input {
                box-shadow: unset;
                border: 1px solid #384044;
                width: 18px;
                min-width: 18px;
                height: 18px;
                min-height: 18px;

                &::after {
                  font-size: 12px;
                }
              }

              .count {
                font-family: "Museo Sans";
                font-size: 12px;
                font-weight: 300;
                line-height: 16px;
                letter-spacing: 0.004em;
                text-align: center;
                background-color: #eaeef1;
                color: #606669;
                padding: 2px 10px;
                border-radius: 4px;
              }
            }
          }
        }

        .facets-list__toggle-btn {
          display: none;
        }

        @for $i from 0 through 4 {
          .facet-tab-#{$i} {
            margin-left: $i * 25px;
          }
        }
      }

      .sidebar__footer {
        display: flex;
        position: fixed;
        gap: 24px;
        background-color: white;
        padding: 27px 16px;
        justify-content: space-between;
        bottom: 0;
        width: calc(100% - 16px);

        .comparator-btn {
          width: 70%;

          .c-cta {
            width: 100%;
          }
        }

        .delete-filters-btn {
          width: 30%;

          .c-cta {
            width: 100%;
          }
        }

        &::after {
          content: "";
          width: calc(100% - 32px);
          height: 1px;
          background-color: #d7d9da;
          position: absolute;
          top: 0;
        }

        .mode-btn {
          display: none;
        }

        @include desktop {
          display: none;
        }
      }

      &.open {
        left: 0;
        .sidebar__header {
          display: flex;
          padding: 8px 4px;
          border-bottom: 1px solid #d7d9da;
        }
        .sidebar__close-btn {
          display: block;
          position: absolute;
          top: 24px;
          transition: 0.5s ease-out;
          right: 24px;
          z-index: 8;
          cursor: pointer;
        }
      }
    }

    &__body {
      @include desktop {
        width: 78.5%;
      }

      .result__list {
        display: flex;
        flex-direction: column;
        .list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 24px;
          justify-content: center;

          @include desktop {
            justify-content: start;
          }
        }

        &-item {
          border-radius: 8px;
          border: 1px solid #d7d9da;
          box-shadow: 0px 4px 8px 0px #0000001a;
          max-width: 100%;

          @include desktop {
            width: calc(320px - 16px);
            border-radius: unset;
            border: initial;
            box-shadow: initial;
          }
        }

        &-pagination {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin: 40px auto;
          height: 32px;
          border: 1px solid #d7d9da;
          border-radius: 16px;
          .pagination {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 16px;
            height: 100%;
            border-right: 1px solid #d7d9da;
            border-left: 1px solid #d7d9da;

            li {
              height: 100%;
              display: flex;
              align-items: center;

              &.active {
                font-weight: 600;
                border-bottom: 1px solid #30a2bf;
              }

              a {
                text-decoration: none;
              }
            }
          }
          .prev,
          .next {
            width: 30px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;

            &::before {
              color: #30a2bf;
            }
          }
        }
      }

      .body__main-nav-wrapper {
        .main-nav__select,
        .mode-btn,
        .comparator-btn,
        .main-nav__hotels-btn__hotels {
          display: none;
        }
        @include desktop {
          display: none;
        }

        .main-nav__hotels-btn__filter {
          display: inline-flex;
          gap: 8px;
          font-family: "Museo Sans";
          font-size: 16px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0.005em;
          color: #2f5a76;
          cursor: pointer;
          margin-bottom: 25px;
        }

        .main-nav__hotels-links {
          display: block;
          .bwd-menu {
            &__wrapper {
              max-width: initial;
              margin-left: 4px;
              margin-bottom: 25px;
              padding: 0;

              &__content {
                justify-content: start;
              }
            }

            &__links {
              display: inline-flex;
              border-bottom: unset;
              overflow-x: auto;
              gap: 8px;

              &::-webkit-scrollbar {
                display: none;
              }

              @include desktop {
                overflow-x: initial;
              }

              &-link {
                padding: 6px 8px;
                border: 1px solid #384044;
                border-radius: 8px;
                font-family: "Museo Sans";
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                letter-spacing: 0.0025em;
                text-align: left;

                &:hover {
                  &::after {
                    content: "";
                    display: none;
                  }
                }

                &.mod--selected {
                  background-color: #acbdc8;
                  &::after {
                    content: "";
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .result__comparator-bar {
        display: none;
      }

      &-links {
        display: none;
        @include desktop {
          display: flex;
        }
        .bwd-menu {
          &__wrapper {
            max-width: initial;
            margin-left: 4px;
            margin-bottom: 25px;
            padding: 0;

            &__content {
              justify-content: start;
            }
          }

          &__links {
            display: inline-flex;
            border-bottom: unset;
            overflow-x: auto;
            gap: 8px;

            &::-webkit-scrollbar {
              display: none;
            }

            @include desktop {
              overflow-x: initial;
            }

            &-link {
              padding: 6px 8px;
              border: 1px solid #384044;
              border-radius: 8px;
              font-family: "Museo Sans";
              font-size: 14px;
              font-weight: 300;
              line-height: 20px;
              letter-spacing: 0.0025em;
              text-align: left;

              &:hover {
                &::after {
                  content: "";
                  display: none;
                }
              }

              &.mod--selected {
                background-color: #acbdc8;
                &::after {
                  content: "";
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &__comparing {
      display: none;
    }
  }
}
