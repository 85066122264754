.bwd-weather__button {
  background-image: linear-gradient(90deg, #1d6173 0%, #1c3647 100%);
  background-repeat: no-repeat;
  background-size: 0;
  border-radius: 40px;
  color: #2f5a76;
  font-weight: 600;
  margin: 0 2px;
  padding: 9px 31px;
  position: relative;
  transition: all 0.5s ease;
  z-index: 1;

  &:hover {
    background-size: 100%;
    color: white;
  }

  &::before {
    border: 2px solid #2f5a76;
    border-radius: 40px;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.5s ease;
    z-index: -1;
  }

  &__active {
    background-size: 100%;
    color: white;
    cursor: default;

    &::before {
      border: 2px solid #2f5a7600;
    }
  }
}
