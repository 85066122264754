.bwd-timeline {
  &__wrapper {
    background-color: #f5f5f6;
    padding: 120px 0;
  }

  &__timeline {
    display: flex;
    flex-direction: column;
    gap: 64px;
    margin: 64px auto 0;
    max-width: 649px;
    padding-left: 80px;
    padding-right: 46px;
    position: relative;

    @include tablet {
      gap: 104px;
      padding-left: 0;
      padding-right: 0;
    }

    &::before {
      background-color: #2f5a76;
      bottom: 0;
      content: "";
      left: 52px;
      position: absolute;
      top: 0;
      width: 1px;

      @include tablet {
        left: 50%;
      }
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    @include tablet {
      &:nth-child(even) & {
        &__icon {
          justify-content: flex-start;
          order: 3;
        }
        &__text {
          text-align: end;
        }
      }
    }

    &__icon,
    &__text {
      width: 100%;
      @include tablet {
        width: 281px;
      }
    }

    &__icon {
      align-items: center;
      display: flex;
      order: 1;

      @include tablet {
        justify-content: flex-end;
      }

      .cmp-icon {
        height: 64px;
        width: 64px;
      }
    }

    &__text {
      order: 2;

      .description {
        color: #606669;
        font-family: Museo Sans;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.0025em;
        line-height: 20px;
      }

      .title {
        color: #384044;
        font-family: Sfizia;
        font-size: 26px;
        font-style: italic;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 8px;
      }
    }

    &__marker {
      background-color: #f5f5f6;
      border: 1px solid #2f5a76;
      border-radius: 16px;
      height: 16px;
      left: -35.5px;
      position: absolute;
      width: 16px;

      @include tablet {
        left: calc(50% - 7.5px);
      }
    }
  }
}
