.bwd-contact-form {
  &__dropdown {
    position: fixed;
    right: 24px;
    bottom: 72px;
    z-index: $z-sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 88px;
    background-image: linear-gradient(to right, #e0e0e0, #ffffff);
    border-radius: 16px;
    cursor: pointer;

    background-image: url(https://s7g10.scene7.com/is/image/barcelo/contactform:Square?wid=400&hei=400&fit=crop,1&qlt=60&fmt=avif-alpha&cache=validate,on,on&defaultImage=default-dm);
    background-size: 177px;
    background-position: 51% 42%;

    @include desktop {
      right: 120px;
    }

    &.mod--open {
      display: block;
      padding: 16px 16px 17px 0;
      width: calc(100% - 48px);
      height: auto;
      overflow: hidden;

      background-image: linear-gradient(to right, #e0e0e0, #ffffff);
      background-size: unset;

      @include desktop {
        width: calc(100% - 234px);
      }

      .bwd-contact-form {
        &__btn {
          display: none;
        }

        &__content {
          display: flex;
          flex-direction: column;
          margin-left: 130px;
          height: 100%;

          @include desktop {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-left: 156px;
          }

          &-img {
            position: absolute;
            top: 0;
            display: block;
            width: 120px;
            height: 100%;
            object-fit: cover;
          }

          // &-info {
          //     flex-shrink: 0;
          // }

          &-title {
            margin-top: 0;
            margin-bottom: 5px;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #454545;

            @include desktop {
              font-size: 20px;
              line-height: 28px;
            }
          }

          &-btn {
            margin-top: 21px;
            padding: 5px 32px;
            width: fit-content;
            min-width: 140px;
            height: 40px;
            background-image: linear-gradient(to left, #30a2bf, #2f5a76);
            border-radius: 100px;
            color: #ffffff;

            @include desktop {
              margin-top: 0;
              padding: 15px 32px;
              min-width: 146px;
              height: 48px;
            }
          }

          &-contact {
            display: block;
            font-weight: 300;
          }
        }
      }
    }

    &.mod--hide {
      display: none;
    }
  }

  &__content {
    display: none;

    &-img {
      display: none;
    }
  }

  &__btn {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    padding: 11px;
    // background-image: linear-gradient(to left, #30a2bf, #2f5a76);

    &-icon {
      font-size: 27px;
      color: #ffffff;
    }

    &-contact {
      display: none;
    }
  }

  &__modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-menu;
    width: 100%;
    height: 100vh;
    background-color: #00000066;
    backdrop-filter: blur(1px);

    @include desktop {
      height: 100%;
    }

    &-wrapper {
      position: absolute;
      height: auto;
      max-height: 550px;
      border-radius: 16px;
      background-color: #ffffff;
      overflow: auto;

      @include desktop {
        width: auto;
        max-width: initial;
        height: auto;
        max-height: 620px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-image: linear-gradient(to top, #30a2bf, #2f5a76);
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background-color: #2f5a76;
          background-image: initial;
        }
      }
    }

    &-close {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 12px;
      height: 12px;
      background-image: url("/content/dam/barcelo/commons/icons/ui/close.svg");
      background-size: contain;
      cursor: pointer;

      &--moreMargin {
        top: 38px;
        right: 38px;
      }
    }

    &.mod--open {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  // First modal - Welcoming
  &__welcoming,
  &__thankYou {
    .cmp-title {
      &__richtext {
        div,
        p {
          margin-top: 8px;
          font-family: "Museo Sans";
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.03em;

          div,
          p {
            &:first-of-type {
              margin-top: 0;
              margin-bottom: 8px;
              font-family: "Sfizia";
              font-size: 40px;
              line-height: 48px;
              letter-spacing: 0.09px;
            }
          }
        }
      }
    }

    &-btn {
      margin-top: 24px;
      padding: 8px 32px;
      //width: 159px;
      height: 40px;
      border-radius: 100px;
      background-image: linear-gradient(to right, #30a2bf, #2f5a76);
      color: #ffffff;
    }
  }

  &__welcoming {
    display: none;

    &.mod--open {
      display: flex;
      flex-direction: row;
    }

    &.thankYou-modal {
      .bwd-contact-form {
        &__thankYou {
          &-title,
          &-mail,
          &-btn {
            display: block;
          }
        }

        &__welcoming {
          &-title,
          &-text,
          &-btn,
          &-contact {
            display: none;
          }
        }
      }
    }

    &-bg {
      display: none;
      width: 328px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      background: black;

      .image {
        height: 100%;
      }

      @include desktop {
        display: block;
      }
    }

    &-info {
      padding: 72px 24px 24px;
      width: 366px;

      @include desktop {
        width: 328px;
      }
    }

    &-title {
      .cmp-title {
        &__text {
          font-size: 40px;
          line-height: 48px;
          font-style: italic;
          letter-spacing: 0;
          text-align: left;
        }
      }
    }

    &-text {
      margin-top: 24px;
      letter-spacing: 0.01em;
    }

    &-contact {
      margin-top: 24px;
      letter-spacing: 0.01em;
    }

    &-number {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 8px;
    }

    &-icon {
      display: block;
      width: 19px;
      height: 19px;
      margin-right: 11px;
      background-image: url("/content/dam/barcelo/commons/icons/ui/phone_lined-black.svg");
      background-size: contain;
      background-repeat: no-repeat;
      filter: brightness(2.6);
    }
  }

  // Last modal - Thank You
  &__thankYou {
    &-title {
      display: none;
    }

    &-mail {
      display: none;
      margin-top: 24px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.01em;
    }

    &-btn {
      display: none;
      margin-bottom: calc(128px - 24px);
      padding: 5px 24px;

      a {
        text-decoration: unset !important;
      }
    }
  }

  // Second modal - Steps
  &__steps {
    display: none;
    padding: 32px;
    width: 366px;

    @include desktop {
      width: 664px;
    }

    &-title {
      margin-top: 40px;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      color: $barcelo-black;

      &--noMargin {
        margin-top: 0;
      }
    }

    &-info {
      margin-top: 8px;
    }

    &-field {
      margin-top: 4px;
      padding: 8px 12px !important;
      border-radius: 8px !important;
      border-color: $barcelo-black !important;
      box-shadow: none !important;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-moz-placeholder,
      &::placeholder {
        color: #606669 !important;
        opacity: 1;
      }
    }

    &-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 40px;
      list-style: none;
    }

    &-groupBtn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-btn {
      background-image: linear-gradient(to left, #30a2bf, #2f5a76);
      border-radius: 100px;
      font-size: 16px;
      line-height: 30px;
      display: inline-block;
      width: 145px;
      height: 35px;
      // vertical-align: middle;
      // white-space: nowrap;
      // box-sizing: border-box;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      transition: 0.3s ease-out;

      @include desktop {
        padding: 5px 0;
        width: 190px;
        height: 40px;
      }

      &:first-of-type:not(:disabled) {
        border: 2px solid #2f5a76;
        background-color: #ffffff;
        background-image: initial;
        // background-image: linear-gradient(to right, #2f5a76 49.95%, transparent 49.96%);
        // background-size: 201% 201%;
        // background-position: 100%;
        color: #2f5a76;

        &:hover {
          background-color: #eaeef1;
          border-color: transparent;
          // background-position: 0;
          // color: #ffffff;
        }

        &:active {
          background: #2f5a76;
          background-color: #2f5a76;
          color: #fff;
        }

        @include desktop {
          padding: 3px 0;
        }
      }

      &:disabled {
        background-color: #eaeef1;
        background-image: initial;
        color: #acbdc8;
        cursor: initial;
      }
    }

    &.mod--open {
      display: block;
    }
  }

  // Second modal - Steps (categories)
  &__step {
    display: flex;
    gap: 10px;
    color: #d7d9da;
    font-size: 12px;
    line-height: 16px;

    &:last-of-type() {
      .bwd-contact-form__step-name {
        flex-shrink: 0;
      }
    }

    &-name {
      display: none;
      color: #606669;

      @include desktop {
        display: block;
      }
    }

    &-separator {
      margin: 0 10px;
      width: 70px;
      height: 1px;
      background-color: #d7d9da;

      &.mod--black-color {
        background-color: $barcelo-black;
      }
    }

    &.mod--black-color {
      color: $barcelo-black;
      font-weight: 600;

      &.mod--active {
        .bwd-contact-form__step-name {
          color: $barcelo-black;
          font-weight: 600;
          display: block;

          &.step-four {
            position: relative;
            display: inline-block;
            max-width: 80px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            // &::after {
            //   content: "Info.";
            //   position: absolute;
            //   white-space: nowrap;
            //   right: 2px;
            //   background: #ffffff;
            // }
          }
        }
      }
    }
  }

  // Second modal (first option) - Step 1
  &__stepOne {
    display: none;
    margin-bottom: 40px;

    &-options {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;

      @include desktop {
        gap: 16px;
        max-height: 190px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-image: linear-gradient(to top, #30a2bf, #2f5a76);
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background-color: #2f5a76;
          background-image: initial;
        }
      }
    }

    &-selectContainer {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 39px;
        right: 12px;
        display: block;
        width: 20px;
        height: 20px;
        background-image: url("/content/dam/barcelo/commons/icons/ui/arrow_down.svg");
        background-size: 80%;
        background-repeat: no-repeat;
        filter: invert(30%);
        cursor: pointer;
      }
    }

    &-select {
      position: relative;
      margin-top: 4px;
      background: none;
      color: #606669;

      &:focus {
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }

      option {
        color: $barcelo-black;

        &:disabled {
          background-color: #60666940;
          color: #ffffff;
        }
      }
    }

    &-label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400 !important;
    }

    &-option {
      padding: 6px 8px;
      width: fit-content;
      height: 32px;
      border: 1px solid $barcelo-black;
      border-radius: 8px;
      color: $barcelo-black;
      font-size: 14px;
      line-height: 20px;
      transition: 0.3s ease-out;

      &:hover {
        @include desktop {
          background-color: $barcelo-black;
          color: #ffffff;
        }
      }

      &.mod--active {
        background-color: $barcelo-black;
        color: #ffffff;

        &:hover {
          &:not(:focus) {
            @include desktop {
              background-color: #384044de;
            }
          }
        }
      }
    }

    &.mod--open {
      display: block;
    }
  }

  // Second modal (second option) - Step 2
  &__stepTwo {
    display: none;
    margin-bottom: 36px;

    @include desktop {
      margin-bottom: 40px;
    }

    &-options {
      display: block;
      margin-top: 36px;

      @include desktop {
        margin-top: 40px;
      }

      .bwd-contact-form__stepTwo-option {
        border-color: $barcelo-black;
      }
    }

    &-label {
      flex-shrink: 0;
      font-weight: 400;
    }

    &-guestCapacity {
      max-width: 382px;
    }

    &-column {
      max-height: 102px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-image: linear-gradient(to top, #30a2bf, #2f5a76);
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background-color: #2f5a76;
          background-image: initial;
        }
      }
    }

    &-row {
      display: flex;
      flex-direction: column;

      // &:not(:nth-last-child(-n + 2)) {
      //   margin-bottom: 15px;
      // }
    }

    &.mod--open {
      display: block;
    }
  }

  // Second modal (third option) - Step 3
  &__stepThree {
    display: none;
    margin-bottom: 36px;

    @include desktop {
      margin-bottom: 32px;
    }

    &-column {
      display: flex;
      flex-direction: column;
      margin-top: 36px;

      @include desktop {
        margin-top: 32px;
      }
    }

    &-label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }

    &-date {
      max-width: 382px;
      appearance: none !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-transform: uppercase;

      &:invalid {
        color: #606669;
      }

      // Chrome, Safari and Edge
      &::-webkit-calendar-picker-indicator {
        display: none;
      }
    }

    &.mod--open {
      display: block;
    }
  }

  // Second modal (fourth option) - Step 4
  &__stepFour {
    display: none;
    margin-bottom: 26px;

    &-column {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 26px;

      @include desktop {
        flex-direction: row;
      }
    }

    &-row {
      width: 100%;

      @include desktop {
        width: 279px;
      }
    }

    &-email {
      margin-top: 14px;
      width: 100%;

      @include desktop {
        width: 582px;
      }
    }

    &-comment {
      position: relative;
      margin-top: 14px;
      width: 100%;

      @include desktop {
        width: 582px;
      }

      textarea {
        margin-top: 4px;
        padding: 12px;
        min-height: 72px;
        resize: vertical;
        //border-color: $barcelo-black;
        border: initial;
        outline: 1px solid $barcelo-black;
        border-radius: 8px;

        &::-webkit-scrollbar {
          width: 5px;
          height: 0;
        }

        &::-webkit-scrollbar-thumb {
          background-image: linear-gradient(to top, #30a2bf, #2f5a76);
          border-radius: 10px;
          cursor: pointer;

          &:hover {
            background-color: #2f5a76;
            background-image: initial;
          }
        }
      }
    }

    &-counter {
      top: 0;
      right: 0;
      padding-right: 0;
      background: transparent;
    }

    &-toggleBackground {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 26px;
      padding: 16px;
      width: 100%;
      border-radius: 8px;
      background-color: #f5fafc;

      @include desktop {
        width: 575px;
      }
    }

    &-switch {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 16px;
      cursor: pointer;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
          & + .bwd-contact-form__stepFour-slider {
            &::before {
              -webkit-transform: translateX(20px);
              -ms-transform: translateX(20px);
              transform: translateX(20px);
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    &-slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: #d7d9da;
      border-radius: 100px;
      transition: 0.4s;

      &::before {
        content: "";
        position: absolute;
        left: -7px;
        bottom: -2.4px;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid #d7d9da;
        transition: 0.4s;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 100px;
        opacity: 0;
        background-image: linear-gradient(to left, #30a2bf, #315973);
        transition: opacity 0.4s ease-out;
      }
    }

    &-label {
      max-width: 162px;
      font-weight: 400;

      @include desktop {
        max-width: initial;
      }
    }

    &-checkboxGroup {
      display: flex;
      margin-top: 16px;

      &:first-of-type {
        margin-top: 26px;
      }

      &:last-of-type {
        label {
          letter-spacing: 0.0065em;
        }
      }

      .bwd-contact-form__stepFour {
        &-conditions {
          flex-shrink: 0;
          margin-right: 9px;
          width: 22px;
          height: 22px;
          border-color: $barcelo-black;
          box-shadow: initial;
          position: relative;
          z-index: 1;
          border-radius: 4px;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.3s ease-out;
            background-image: linear-gradient(to left, #268299 0%, #26485e 100%, #26485e 100%);
            z-index: -1;
            border-radius: 4px;
          }

          &:checked {
            background: transparent;
            background-image: linear-gradient(to left, #30a2bf, #315973);
            width: 22px;
            height: 22px;
            border-radius: 4px;
            box-shadow: initial;
            border: initial;

            &::before {
              opacity: 1;
            }

            &::after {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
              line-height: 22px;
            }
          }
        }

        &-conditionsLabel {
          font-weight: 300;

          @include desktop {
            letter-spacing: 0.01em;
          }

          a {
            font-weight: 400;
          }
        }
      }
    }

    &-message {
      margin-top: 26px;
    }

    &.mod--open {
      display: block;
    }
  }

  &__mandatoryField {
    margin-left: 4px;
    color: #e40f13;
  }

  &__error {
    &-message {
      display: none;
      margin-top: 10px;
      color: #e40f13;

      &.mod--open {
        display: block;
      }

      &.mod--openPrivacy {
        display: flex;
      }
    }

    &-privacy {
      margin-left: 32px;
    }

    &-icon {
      display: block;
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }

  a {
    text-decoration: underline;
  }
}

// Modifiers
.mod--disable-scroll {
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mod--primary-cta {
  &-v1,
  &-v2 {
    position: relative;
    z-index: 1;
    transition: opacity 0.3s;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: -1;
      border-radius: 100px;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &-v1 {
    &::before {
      background-image: linear-gradient(to left, #268299 0%, #26485e 100%, #26485e 100%);
    }
  }

  &-v2 {
    &::before {
      background-image: linear-gradient(to right, #268299 0%, #26485e 100%, #26485e 100%);
    }
  }
}

.mod--invalidField {
  border-color: #e40f13 !important;
}

.mod--warning {
  color: #e40f13;
}

.mod--selected {
  color: $barcelo-black;
}
