.bwd-multi-launchers {
  &__wrapper {
    &.mod--two-inline {
      .bwd-multi-launchers__header {
        display: none;
      }
    }

    &.mod--three-inline {
      margin: 64px 24px;
      .bwd-multi-launchers__header {
        margin-bottom: 32px;
        &-title {
          .cmp-title {
            &__text {
              display: none;
            }

            &__subtitle {
              font-size: 40px;
            }
          }
        }

        &-description {
          display: none;
        }
      }
    }

    &.mod--three-inline-abstract {
      margin: 40px 24px;
      margin-right: 0;
      .bwd-multi-launchers__header {
        max-width: calc(100% - 24px);
        margin-right: auto;
        margin-bottom: 24px;
        &-title {
          .cmp-title {
            &__text {
              color: #0f1418;
            }
            &__subtitle {
              margin-bottom: 16px;
            }
          }
        }

        &-description {
          text-align: center;
          color: #606669;
        }
      }
    }

    &.mod--three-right-side {
      margin: 64px 24px;
      .bwd-multi-launchers__header {
        margin-bottom: 32px;
        &-title {
          .cmp-title {
            &__text {
              display: none;
            }

            &__subtitle {
              // text-align: left;
              font-size: 40px;
            }
          }
        }

        &-description {
          display: none;
        }

        &-cta {
          display: inline-block;
          font-family: "Museo Sans";
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.005em;
          text-align: left;
          margin-top: 32px;
          color: #384044;
        }
      }
    }

    &.mod--four-inline {
      margin: 16px 24px;
      margin-right: 0;
      .bwd-multi-launchers__header {
        max-width: calc(100% - 24px);
        margin-right: auto;
        margin-bottom: 16px;
        &-title {
          .cmp-title {
            &__text {
              color: #0f1418;
            }
            &__subtitle {
              font-size: 40px;
              margin-bottom: 16px;
            }
          }
        }

        &-description {
          color: #606669;
          text-align: center;
        }
      }
    }

    &.mod--six-inline {
      margin: 64px 24px;
      margin-right: 0;
      .bwd-multi-launchers__header {
        max-width: calc(100% - 24px);
        margin-right: auto;
        margin-bottom: 16px;
        &-title {
          .cmp-title {
            &__text {
              color: #0f1418;
            }
            &__subtitle {
              font-size: 40px;
              margin-bottom: 16px;
            }
          }
        }

        &-description {
          color: #606669;
          text-align: center;
        }
      }
    }

    &.mod--six-over-two-lines {
      margin: 16px 24px;
      .bwd-multi-launchers__header {
        width: 100%;
        margin-bottom: 40px;
        &-title {
          .cmp-title {
            &__text {
              display: none;
              color: #0f1418;
            }
            &__subtitle {
              font-size: 40px;
              margin-bottom: 48px;
            }
          }
        }

        &-description {
          color: #606669;
          text-align: center;
        }
      }
    }

    &.mod--x-over-lines {
      margin: 16px 24px;
      .bwd-multi-launchers__header {
        width: 100%;
        margin-bottom: 40px;
        &-title {
          .cmp-title {
            &__text {
              display: none;
              color: #0f1418;
            }
            &__subtitle {
              font-size: 40px;
              margin-bottom: 48px;
            }
          }
        }

        &-description {
          color: #606669;
          text-align: center;
        }
      }
    }
  }
  &__block {
    &.mod--two-inline {
      margin: 24px;
      .bwd-multi-launchers__block-wrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }

    &.mod--three-inline {
      .bwd-multi-launchers__block {
        &-cta {
          margin-bottom: 48px;
        }

        &-wrapper {
          display: flex;
          flex-direction: row;
          gap: 16px;
          overflow-x: auto;
        }
      }
    }

    &.mod--three-inline-abstract {
      .bwd-multi-launchers__block-wrapper {
        display: flex;
        flex-direction: row;
        gap: 24px;
        overflow-x: auto;
      }
    }

    &.mod--three-right-side {
      .bwd-multi-launchers__block-wrapper {
        display: flex;
        flex-direction: column;
        gap: 43px;
      }
    }

    &.mod--four-inline {
      .bwd-multi-launchers__block-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0px;
        overflow-x: auto;
      }
    }

    &.mod--six-inline {
      .bwd-multi-launchers__block-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0px;
        overflow-x: auto;
      }
    }

    &.mod--six-over-two-lines {
      .bwd-multi-launchers__block-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 24px;
      }
    }

    &.mod--x-over-lines {
      .bwd-multi-launchers__block-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 24px;
      }
    }
  }

  .mod--underline-small {
    a::before {
      content: "";
      width: 24px !important;
    }
    &:hover {
      a::before {
        content: "";
        width: 100% !important;
      }
    }
  }

  &.mod--align-left {
    .mod--three-inline-abstract {
      .bwd-multi-launchers__header {
        .cmp-title {
          text-align: left;
        }

        .generic-text {
          text-align: left;
          margin: initial;
        }
      }

      .bwd-launcher {
        .bwd-launcher-content__label {
          text-align: left;
          padding-left: 0;
        }
      }
    }
    .mod--four-inline {
      .bwd-multi-launchers__header {
        .cmp-title {
          text-align: left;
        }

        .generic-text {
          text-align: left;
          margin: initial;
        }
      }
    }

    .mod--six-inline {
      .bwd-multi-launchers__header {
        .cmp-title {
          text-align: left;
        }

        .generic-text {
          text-align: left;
          margin: initial;
        }
      }
    }
    .mod--six-over-two-lines {
      .bwd-multi-launchers__header {
        .generic-text {
          text-align: left;
          margin: initial;
        }
      }
    }
    .mod--x-over-lines {
      .bwd-multi-launchers__header {
        .generic-text {
          text-align: left;
          margin: initial;
        }
      }
    }
  }
}

@media (min-width: $tablet-min) {
  .bwd-multi-launchers {
    &__block {
      &.mod--three-inline-abstract {
        .bwd-multi-launchers__block-wrapper {
          justify-content: center;
        }
      }
    }
  }
}

@media (min-width: $desktop-min) {
  .bwd-multi-launchers {
    &__wrapper {
      max-width: 1440px;
      margin: 0 auto !important;
      background-color: transparent;
      &.mod--three-inline {
        margin: 0;
        padding: 64px;
        .bwd-multi-launchers__header {
          max-width: 60%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 48px;
          &-title {
            margin-bottom: 24px;
            .cmp-title {
              &__text {
                display: block;
                color: #0f1418;
              }

              &__subtitle {
                font-size: 64px;
              }
            }
          }

          &-description {
            display: block;
            text-align: center;
            color: #606669;
            max-width: 90%;
            margin: auto;
          }
        }
      }
      &.mod--three-inline-abstract {
        margin: 0;
        padding: 64px;
        .bwd-multi-launchers__header {
          max-width: 52%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 32px;
          &-title {
            margin-bottom: 24px;
            .cmp-title {
              &__text {
                display: block;
              }

              &__subtitle {
                font-size: 64px;
              }
            }
          }

          &-description {
            display: block;
            color: #606669;
            max-width: 90%;
            margin: auto;
          }
        }
      }
      &.mod--three-right-side {
        display: flex;
        gap: 48px;
        margin: 0;
        padding: 64px;
        .bwd-multi-launchers__header {
          width: calc(50% - 24px);
          &-title {
            .cmp-title {
              &__subtitle {
                font-size: 64px;
              }
            }
          }
        }
        .bwd-multi-launchers__container {
          width: calc(50% - 24px);
        }
      }
      &.mod--four-inline {
        padding: 64px 24px;
        margin: 0;
        .bwd-multi-launchers__header {
          max-width: 52%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 48px;
          &-title {
            margin-bottom: 24px;
            .cmp-title {
              &__text {
                display: block;
              }

              &__subtitle {
                // text-align: center;
                font-size: 64px;
              }
            }
          }

          &-description {
            display: block;
            text-align: center;
            color: #606669;
            max-width: 90%;
            margin: auto;
          }
        }
      }
      &.mod--six-inline {
        padding: 64px 24px;
        margin: 0;
        .bwd-multi-launchers__header {
          max-width: calc(100% - 25%);
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 16px;
          &-title {
            margin-bottom: 16px;
            .cmp-title {
              &__text {
                display: block;
              }

              &__subtitle {
                font-size: 64px;
              }
            }
          }

          &-description {
            display: block;
            text-align: center;
            color: #606669;
            max-width: 90%;
            margin: auto;
          }
        }
      }

      &.mod--six-over-two-lines {
        padding: 64px;
        margin: 0;
        .bwd-multi-launchers__header {
          &-title {
            .cmp-title {
              &__subtitle {
                font-size: 64px;
              }
            }
          }
        }
      }

      &.mod--x-over-lines {
        padding: 64px;
        margin: 0;
        .bwd-multi-launchers__header {
          &-title {
            .cmp-title {
              &__subtitle {
                font-size: 64px;
              }
            }
          }
        }
      }
    }
    &__block {
      &.mod--two-inline {
        margin: 64px;
        .bwd-multi-launchers__block-wrapper {
          flex-direction: row;
          justify-content: center;
        }
      }

      &.mod--three-inline {
        .bwd-multi-launchers__block {
          &-cta {
            display: none;
          }
          &-wrapper {
            display: flex;
            flex-direction: row;
            gap: 16px;
            overflow-x: initial;
          }
        }
      }

      &.mod--three-inline-abstract {
        .bwd-multi-launchers__block-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: initial;
          gap: 16px;
        }
      }

      &.mod--four-inline {
        margin: 0px;
        .bwd-multi-launchers__block-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 16px;
          overflow-x: initial;
        }
      }

      &.mod--six-inline {
        margin: 0px;
        .bwd-multi-launchers__block-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 16px;
          overflow-x: initial;
        }
      }

      &.mod--six-over-two-lines {
        .bwd-multi-launchers__block-wrapper {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          gap: 24px;
        }
      }

      &.mod--x-over-lines {
        .bwd-multi-launchers__block-wrapper {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          gap: 24px;
        }
      }
    }
    //POLICIES
    &.mod--align-left {
      .mod--three-inline {
        .bwd-multi-launchers__header {
          max-width: initial;
          margin-left: initial;
          margin-right: initial;
          .cmp-title {
            text-align: left;
          }

          .generic-text {
            text-align: left;
            margin: initial;
          }
        }

        .bwd-launcher {
          .bwd-launcher-content__label {
            text-align: left;
          }
        }
      }
      .mod--three-inline-abstract {
        .bwd-multi-launchers__header {
          max-width: initial;
          margin-left: initial;
          margin-right: initial;
          .cmp-title {
            text-align: left;
          }

          .generic-text {
            text-align: left;
            margin: initial;
          }
        }

        .bwd-launcher {
          .bwd-launcher-content__label {
            text-align: left;
            padding-left: 0;
          }

          .bwd-launcher-content__description {
            text-align: left;
          }
        }
      }

      .mod--four-inline {
        .bwd-multi-launchers__header {
          max-width: initial;
          margin-left: initial;
          margin-right: initial;
          .cmp-title {
            text-align: left;
          }

          .generic-text {
            text-align: left;
            margin: initial;
          }
        }
      }

      .mod--six-inline {
        .bwd-multi-launchers__header {
          max-width: initial;
          margin-left: initial;
          margin-right: initial;
          .cmp-title {
            text-align: left;
          }

          .generic-text {
            text-align: left;
            margin: initial;
          }
        }
      }
    }
  }
}
