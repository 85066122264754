.bwd-invitation-header {
  &__wrapper {
    padding: 48px 0;
    color: $barcelo-black;
  }

  &__title {
    .cmp-title {
      &__richtext {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &__info {
    &-title {
      margin-top: 30px;

      @include desktop {
        margin-top: 64px;
      }

      .cmp-title {
        &__richtext {
          font-size: 40px;
          line-height: 35px;

          @include desktop {
            font-size: 56px;
            line-height: 64px;
          }
        }
      }
    }

    &-text {
      margin: 16px auto 0;
      width: calc(100% - 48px);
      text-align: center;
      color: #606669;

      @include desktop {
        max-width: 654px;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;

    @include desktop {
      margin-top: 64px;
      flex-direction: row;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      width: 100%;

      &:first-of-type {
        margin-top: 0;
      }

      @include desktop {
        margin-top: 0;
        max-width: 421px;
      }
    }

    &__title {
      @include desktop {
        margin-top: 30px;
      }

      .cmp-title {
        &__richtext {
          font-size: 26px;
          line-height: 32px;
        }
      }
    }

    &__text {
      margin-top: 8px;
      line-height: 20px;
      font-weight: 400;
      text-align: center;
      color: #606669;

      &--lightGray {
        color: #757575;
      }

      &--thin {
        font-weight: 300;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    width: 50px;
  }
}
