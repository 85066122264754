.bwd-destination-feature {
  min-width: 13.88%;

  &__wrapper {
    display: flex;
    gap: 8px;
  }

  &__icon {
    width: 48px;
    height: 48px;
    border: 2px dotted #2f5a76;
    border-radius: 100%;
    aspect-ratio: 1 / 1;

    .cmp-icon {
      padding-bottom: 5px;

      .c-icon {
        height: 24px;
        width: 24px;
      }
    }
  }

  &__content {
    &-title {
      font-family: "Museo Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.0025em;
      margin-bottom: 4px;
      color: #384044;
    }

    &-description {
      font-family: "Museo Sans";
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.0025em;
      color: #384044;
    }
  }
}
