.bwd-related-articles {
  margin-bottom: 48px;

  @include desktop {
    margin-bottom: 56px;
  }
  &__title {
    margin-top: 56px;
    margin-bottom: 64px;
    margin-inline: 20px;

    @include desktop {
      margin-inline: 0px;
    }
  }

  &__description {
    max-width: 864px;
    margin: 0 auto 16px;
    color: #606669;

    &-card {
      margin-bottom: 64px;
    }

    .cmp-text {
      font-family: "Museo Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.0015em;
      text-align: center;
    }
  }

  &__cards {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    :first-child {
      font-family: "Museo Sans";
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.005em;
      color: #ffffff;
      margin: auto;
      padding: 8px 32px;
      border-radius: 100px;
      background: linear-gradient(90deg, #30a2bf 0%, #2f5a76 100%);
      text-decoration: none;
    }
  }
}
