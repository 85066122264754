.bwd-commitment {
  transition:
    background-color 2s,
    padding 2s;
  overflow: hidden;

  @include desktop {
    padding: 0 102px;
    min-height: 861px;
  }

  &:hover {
    @include desktop {
      background-color: $white;
      padding: 0;

      .bwd-commitment {
        &__title,
        &__number {
          color: $grey-light;
        }

        &__title {
          top: 160px;
        }

        &__number {
          top: 53px;
          opacity: 1;
        }

        &__image {
          top: 0;
          max-width: 100%;
          height: 550px;
        }

        &__info {
          max-height: 2000px;
          opacity: 1;
          transition:
            opacity 1.2s cubic-bezier(0, 0, 0.4, 1.1),
            max-height 2s cubic-bezier(0, 0, 0.4, 1.1);
        }
      }
    }
  }

  &__wrapper {
    position: relative;
    margin-bottom: 40px;

    @include desktop {
      margin-bottom: 53px;
    }
  }

  &__commitment {
    display: flex;
    justify-content: center;
    width: 175px;
    height: 107px;
    margin: auto;
    position: relative;
    top: 0;
    z-index: $z-mask;
  }

  &__title {
    position: relative;
    top: 51px;
    font-family: "Sfizia";
    transition: all 2s cubic-bezier(0, 0, 0.4, 1.1);
    font-size: 14px;
    line-height: 17px;
    height: fit-content;
    font-weight: 400;
    letter-spacing: 0.4em;
    text-indent: 0.4em;
    text-align: center;

    &.mod-bgcolor-grey {
      color: #606669;
    }

    &.mod-bgcolor-gold {
      color: #d9d3cf;
    }

    @include desktop {
      top: 42px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__number {
    font-family: "Sfizia";
    transition: all 2s cubic-bezier(0, 0, 0.4, 1.1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0;
    opacity: 0.2;
    color: #606669;

    @include desktop {
      top: 9px;
      left: initial;
      right: initial;
      font-size: 88px;
      line-height: 107px;
    }
  }

  &__commitment {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 332px;

    @include desktop {
      top: 0;
    }
  }

  &__image {
    position: relative;
    top: 0;
    z-index: $z-overground;
    width: 100%;
    height: 292px;
    transition: all 2s cubic-bezier(0, 0, 0.4, 1.1);
    margin: auto;

    @include desktop {
      top: 122px;
      max-width: 1236px;
      height: 666px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 136px;
    z-index: $z-background;
    opacity: 1;
    transition:
      max-height 1.5s cubic-bezier(0, 0, 0.4, 1.1),
      opacity 0.8s cubic-bezier(0, 0, 0.4, 1.1);

    @include desktop {
      position: relative;
      opacity: 0;
      max-height: 0px;
      top: 50px;
      margin-top: 0;
    }

    &__editor {
      top: 150px;
      opacity: 1;
    }

    &-title {
      margin: 0 57px;
      font-family: "Sfizia";
      font-weight: 400;
      width: fit-content;
      text-align: center;

      .cmp-title {
        &__text,
        &__richtext {
          line-height: 35px;
          font-size: 40px;
        }
      }

      @include desktop {
        margin: 0 auto;

        .cmp-title {
          &__text,
          &__richtext {
            line-height: 67.7px;
            font-size: 56px;
          }
        }
      }

      &:before {
        line-height: 1.5em;
      }
    }

    &-description {
      color: #757575;
      font-family: "Museo Sans";
      font-size: 16px;
      font-weight: 400;
      margin: 24px 41px 0;
      max-width: 322px;
      text-align: center;

      .cmp-text {
        &:first-child {
          line-height: 24px;
        }
      }

      @include desktop {
        max-width: 421px;
        margin: 24px 41px 34px;
        letter-spacing: 0.005em;
        color: #606669;
      }
    }
  }

  &.mod-marginTop {
    &-12 {
      margin-top: 12px;
    }

    &-24 {
      margin-top: 24px;
    }

    &-36 {
      margin-top: 36px;
    }

    &-12,
    &-24,
    &-36 {
      @include desktop {
        margin-top: 0;
      }
    }
  }
}
