.bwd-faqs {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 64px 24px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-title {
      .cmp-title {
        text-align: left;

        &__text {
          color: #0f1418;
        }

        &__subtitle {
          font-size: 54px;
          line-height: 56px;
        }
      }
    }

    &-description {
      .cmp-text {
        font-family: "Museo Sans";
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.005em;
        text-align: left;
        color: #606669;
      }
    }
  }

  &__list {
    .accordion-item {
      &.active {
        .accordion-content {
          padding-bottom: 16px;
        }
      }
    }
  }
}

@media (min-width: $desktop-min) {
  .bwd-faqs {
    &__wrapper {
      display: flex;
      flex-direction: row;
      gap: 40px;
      padding: 64px 64px;
      max-width: 1440px;
      margin: 0 auto;
    }

    &__header {
      width: 34%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 40px;

      &-title {
        .cmp-title {
          &__text {
            font-size: 24px;
            line-height: 29.26px;
          }
          &__subtitle {
            font-size: 64px;
          }
        }
      }
    }

    &__list {
      width: 66%;
    }
  }
}
