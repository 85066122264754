.bwd-feature {
  @include desktop {
    width: calc(33% - 64px);
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.mod--number {
      .bwd-feature__title {
        margin-bottom: 24px;
      }
    }
  }

  &__image {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
  }

  &__number {
    font-family: "Sfizia";
    font-size: 64px;
    font-weight: 400;
    line-height: 78px;
    text-align: center;
    margin-bottom: 16px;

    @include desktop {
      font-size: 100px;
    }
  }

  &__title {
    color: #384044;
    margin-bottom: 8px;
    text-align: center;
    & > div.cmp-title {
      & > * {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }

  &__text {
    font-family: "Museo Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #606669;
  }
}
