.bwd-discount-banner {
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;

    button {
      align-items: center;
      background-image: linear-gradient(to right, #1c3647 49.95%, transparent 49.96%);
      background-position: 100%;
      background-size: 201% 201%;
      border-radius: 100px;
      border: 2px solid #1c3647;
      color: #1c3647;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      height: 48px;
      justify-content: center;
      line-height: 30px;
      max-width: 100%;
      min-width: 146px;
      overflow: hidden;
      padding: 9px 17.5px;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: all 0.3s ease-out;
      white-space: nowrap;

      &:hover {
        background-position: 0;
        color: #fff;
      }

      &:disabled {
        cursor: default;
        opacity: 0.8;
      }
    }
  }

  &__code {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.0015em;
    line-height: 31px;
    text-align: center;
  }

  &__subtitle {
    font-weight: 400;
    letter-spacing: 0.005em;
    text-align: center;
  }

  &__title {
    .cmp-title__subtitle {
      font-size: 48px;
      line-height: 56px;
    }

    .cmp-title__text {
      letter-spacing: 0.4em;
      padding-bottom: 24px;
      position: relative;
      text-indent: 0.2em;

      &::after {
        background-color: #384044;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: calc(50% - 32px);
        position: absolute;
        width: 64px;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 64px 24px;
    @include desktop {
      padding: 64px 175px;
    }
  }
}
