.bwd-tabs {
  &.mod--bwdtabs-rounded-cards {
    & > .cmp-tabs {
      padding: 24px 0;
      @include desktop {
        margin: 0 auto;
        max-width: 1440px;
        padding: 64px;
      }

      .c-bwd-teaser {
        @include desktop {
          padding: 0;
        }
      }

      .cmp-tabs__tabpanel {
        padding: 0 24px;

        @include desktop {
          padding: unset;
        }
      }

      & > div.cmp-tabs__tablist {
        display: block;
        margin: 0 0 24px 0;
        overflow-x: hidden;
        padding: 0 24px;

        @include desktop {
          padding: 0;
        }

        .cmp-tabs__tab {
          align-items: center;
          background: unset;
          background-color: #f5f5f6;
          border: 1px solid #d7d9da;
          border-radius: 16px;
          color: #384044;
          display: flex;
          flex-direction: column;
          flex-grow: 0;
          font-size: 16px;
          font-weight: 400;
          gap: 3px;
          height: auto;
          justify-content: center;
          letter-spacing: 0.005em;
          line-height: 24px;
          margin: 0 4px;
          min-width: 172px;
          padding: 6px 12px;
          transition: all 0.5s ease-out;
          user-select: none;
          white-space: normal;
          width: 172px;

          @include desktop {
            font-size: 18px;
            margin: 0 12px;
            min-width: 212px;
            padding: 11.5px 24px;
            width: 212px;
          }

          &:hover {
            background: unset;
            background-color: white;
            border: 1px solid #f5f5f6;
            box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
          }

          &.cmp-tabs__tab--active {
            background-color: white;
            cursor: default;

            &:hover {
              border: 1px solid #d7d9da;
              box-shadow: none;
            }
          }

          .cmp-tabs__label {
            font-family: Sfizia;
            font-size: 11px;
            letter-spacing: 0.3em;
            line-height: 13.41px;

            @include desktop {
              font-size: 13px;
              line-height: 15.85px;
            }
          }

          .cmp-tabs__title {
            margin-top: inherit;
            margin-bottom: inherit;
          }
        }
      }
    }
  }
}
