.bwd-weather {
  &__wrapper {
    line-height: normal;
    margin: 0 auto;
    max-width: 1330px;
    padding: 32px 12px 20px 12px;
    width: 100%;

    @include desktop {
      padding: 64px 0px 52px 0px;
    }
  }

  &__grid {
    display: flex;
    flex-direction: column;

    @include desktop {
      display: grid;
      grid-template-columns: minmax(0, 824px) minmax(0, 506px);
      grid-template-rows: minmax(300px, auto) minmax(0, 446px);
    }

    &__item {
      height: 100%;
      padding: 12px;
      width: 100%;

      & > div {
        border-radius: 16px;
        height: 100%;
        width: 100%;
      }
    }
  }
}
