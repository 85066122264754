.bwd-map-card {
  &__wrapper {
    &.mod--full-height {
      .bwd-map-card__box {
        max-height: initial;
      }
    }
  }

  &__swiper {
    height: 200px;
  }

  &__box {
    margin-bottom: 20px;
    padding: 24px 24px 0;
    width: 100%;
    max-height: 215px;
    overflow: auto;

    @include desktop {
      margin-bottom: 31px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $barcelo-black;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background-color: #384044e8;
      }
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    gap: 12px;
    overflow: auto;
    margin-bottom: 7px; // margin-bottom + padding-bottom = 12px (like in Figma)
    padding-bottom: 5px; // This is to separate the scrollbar from the tags
    white-space: nowrap;

    @include tablet {
      margin-bottom: 9px; // margin-bottom + padding-bottom = 16px (like in Figma)
      padding-bottom: 7px; // This is to separate the scrollbar from the tags
    }

    @include desktop {
      gap: 16px;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $barcelo-black;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background-color: #384044e8;
      }
    }

    span {
      padding: 4px 8px;
      background-color: #eaeef1;
      border-radius: 8px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      text-align: center;

      @include desktop {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__content {
    &-info {
      height: 100%;
    }
  }

  // &__info {
  //   margin-bottom: 20px;
  // }

  &__title {
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
  }

  &__location {
    margin-top: 5px;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 20px;

    @include desktop {
      margin-top: 8px;
    }
  }

  &__description {
    @include ellipsis-multiline(14px, 20px, 4);
    max-height: 100px;

    @include desktop {
      @include ellipsis-multiline(16px, 24px, 4);
    }
  }

  &__cta {
    margin: 0 24px;
    padding: 12px 25px;
    border: 2px solid $barcelo-black;
    border-radius: 100px;
    background-image: linear-gradient(to right, $barcelo-black 49.95%, transparent 49.96%);
    background-size: 201% 201%;
    background-position: 100%;
    font-size: 13px;
    line-height: 21px;
    font-weight: 600;

    @include desktop {
      padding: 8px 32px;
      font-size: 16px;
      line-height: 24px;
    }

    &:hover {
      background-position: 0;
      color: #ffffff;
    }
  }

  .swiper {
    &-pagination {
      bottom: 15px !important;

      &-bullets {
        bottom: 15px !important;
      }

      &-bullet {
        background-color: #fff !important;
        height: 6px !important;
        border-radius: 100px !important;
        margin: 0 4px !important;
        border: initial !important;

        &-active {
          width: 20px !important;
        }
      }
    }

    &-slide {
      margin-right: 0 !important;
    }
  }
}
