.bwd-hotel-detail {
  &__wrapper {
    padding: 16px 16px 0;
    margin: 0 auto;

    @include desktop {
      max-width: 1440px;
      padding: 17px 64px 0;
    }
  }

  &__header-back {
    display: inline-flex;
    color: #384044;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    align-items: flex-end;

    .icon-simple-arrow {
      transform: rotate(180deg);
      display: inline-block;
      font-size: 24px;
      margin-right: 8px;
    }
    @include desktop {
      margin-bottom: 48px;
    }
  }

  &__info {
    margin-top: 24px;

    &-name {
      font-family: "Museo Sans";
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0.0015em;
      text-align: left;
      color: #384044;
      margin-bottom: 8px;
      margin-top: 0;
    }

    &-data {
      margin-bottom: 8px;

      @include desktop {
        display: flex;
        gap: 16px;
        margin-bottom: 0;
      }
      &__country {
        display: flex;
        font-family: "Museo Sans";
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.005em;
        margin-bottom: 8px;

        @include desktop {
          margin-bottom: 0;
        }

        &-icon {
          margin-right: 8px;
          font-size: 24px;
        }
      }

      &__tripadvisor {
        display: flex;
        align-items: center;
        &-rating {
          font-family: "Museo Sans";
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0.0015em;
        }
        &-icon {
          font-size: 24px;
          margin-right: 8px;
        }
      }
    }

    &-awards {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      column-gap: 16px;

      @include desktop {
        flex-direction: row;
        justify-content: space-between;
        column-gap: unset;
        margin-bottom: 32px;
      }

      &__button {
        .svg-icon {
          margin-right: 4px;
          max-width: 17px;
          max-height: 23px;
        }
      }

      &__block {
        display: flex;
        gap: 16px;
        align-items: center;
      }
      &__tag {
        display: flex;
        align-items: center;
        background-color: #eaeef1;
        padding: 4px 8px;
        font-family: "Museo Sans";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.004em;
        border-radius: 8px;
        &-icon {
          border: 1px solid #384044;
          font-size: 16px;
          margin-right: 8px;
        }
      }

      &__contact {
        .c-cta {
          width: calc(100% - 32px);
          margin: 32px 16px 24px;

          @include desktop {
            margin: 0;
            width: auto;
            min-width: 280px;
          }
        }
      }
    }
  }

  &__gallery {
    display: none;
    @include desktop {
      display: flex;
      gap: 8px;
      &-principal {
        width: calc(50% - 4px);
        display: flex;
        flex-wrap: wrap;
        height: 461px;
        align-content: flex-start;

        &__image {
          width: 100%;
          height: 100%;

          .image {
            width: 100%;
            height: 100%;
          }
        }
      }

      &-group {
        width: calc(50% - 4px);
        display: flex;
        flex-wrap: wrap;
        height: 461px;
        align-content: flex-start;
        gap: 8px;

        &__image {
          min-width: calc(50% - 4px);
          width: calc(50% - 4px);
          height: 226px;

          .image {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &__swiper {
    height: 260px;
    @include desktop {
      display: none !important;
    }

    .swiper-pagination {
      &-bullets {
        bottom: 15px !important;
      }

      &-bullet {
        background-color: #fff !important;
        height: 6px !important;
        border-radius: 100px !important;
        margin: 0 4px !important;
        border: initial !important;
        &-active {
          width: 20px !important;
        }
      }
    }
  }
}
