.bwd-article-text {
  .cmp-text {
    margin: auto;
    padding: 0 24px;
    width: 100%;
    font-weight: 400;

    @include desktop {
      padding: 0;
      max-width: 644px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 34px;
      font-weight: 600;
      color: $barcelo-black;
    }
  }

  // Modifiers - policies
  &.mod--paddingTop40 {
    padding-top: 40px;
  }

  &.mod--paddingTop64 {
    padding-top: 64px;
  }

  &.mod--paddingBottom64 {
    padding-bottom: 64px;
  }

  &.mod--paddingTop16 {
    .cmp-text {
      p {
        padding-top: 16px;

        &:first-of-type {
          padding-top: 0;
        }
      }
    }
  }

  &.mod--text16 {
    .cmp-text {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.mod--text20 {
    .cmp-text {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &.mod--blackColor {
    .cmp-text {
      color: $barcelo-black;
    }
  }

  &.mod--grayColor {
    .cmp-text {
      color: #606669;
    }
  }
}
