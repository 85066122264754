.bwd-wedding-kit {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-weight: 400;
    max-width: 1090px;
    align-items: center;
    margin: auto;
    padding: 64px 24px;
  }

  &__tag {
    font-family: "Sfizia";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4em;
    text-align: center;

    &::after {
      content: "";
      width: 64px;
      height: 1px;
      background-color: #606669;
      margin: 24px auto 0;
      display: block;
    }
  }

  &__title {
    font-size: 38px;
    line-height: 40px;

    @include desktop {
      font-size: 54px;
      line-height: 64px;
    }

    .cmp-title__richtext {
      font-family: "Museo Sans";
      font-size: 38px;
      line-height: 40px;

      @include desktop {
        font-size: 54px;
        line-height: 64px;
      }
    }

    i {
      font-family: "Sfizia";
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
  }

  &__open {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    padding: 9px 32px;
    letter-spacing: 0.005em;
    border: 2px solid #384044;
    border-radius: 48px;
  }

  &__modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 6;
    top: 0;
    background-color: rgba(179, 179, 179, 0.25);
    backdrop-filter: blur(4px);
    display: none;
    padding: 16px;

    &.mod--open {
      display: flex;
    }

    &__form,
    &__response {
      font-size: 16px;
      padding: 32px 24px;
      position: relative;
      background-color: white;
      border-radius: 16px;
      overflow: auto;
      max-height: calc(100vh - 32px);
      width: 100%;
      max-width: 664px;

      @include desktop {
        padding: 32px;
      }
    }

    &__close {
      position: absolute;
      top: 38px;
      right: 30px;
      width: 12px;
      height: 12px;

      @include desktop {
        right: 38px;
      }

      &::before,
      &::after {
        content: "";
        width: 12px;
        height: 2px;
        background-color: #384044;
        display: block;
        position: absolute;
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.0015em;
      margin-bottom: 8px;
      width: 90%;
    }

    &__subtitle {
      font-weight: 300;
      line-height: 24px;
    }

    &__input {
      margin-right: 18px;

      &.mod--error {
        .bwd-wedding-kit__modal__input {
          &__label {
            &::after {
              content: "*";
              color: #e40f13;
              display: inline-block;
              margin-left: 4px;
            }
          }

          &__value {
            border-color: #e40f13;
          }
        }
      }

      &__label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        display: inline-block;
        margin-bottom: 4px;

        &[for="wedding-kit-name"] {
          margin-top: 26px;
        }
      }

      .bwd-wedding-kit__modal__input__value {
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.005em;
        border: 1px solid #384044;
        padding: 8px 12px;
        border-radius: 8px;
        margin-bottom: 10px;

        &[for="wedding-kit-name"] {
          margin-bottom: 14px;
        }

        &:focus {
          border-radius: 8px;
        }
      }
    }

    &__checkbox {
      display: flex;
      margin-right: 18px;
      margin-top: 16px;

      &.mod--error {
        .bwd-wedding-kit__modal {
          &__error {
            display: flex;
          }

          &__checkbox__value {
            border-color: #e40f13;
          }
        }
      }

      &__label {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.005em;
      }

      &__content {
        display: flex;
        flex-direction: column;
      }

      .bwd-wedding-kit__modal__checkbox__value {
        width: 18px;
        height: 18px;
        margin: 3px 11px 3px 3px;
        border-radius: 4px;
        box-shadow: none;
        flex-shrink: 0;

        &:checked {
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #384044;
          border-radius: 4px;
          box-shadow: none;

          &::after {
            font-size: 10px;
            line-height: initial;
            padding: 2px 1px 0 0;
          }
        }
      }

      a {
        text-decoration: underline;
      }
    }

    &__error {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: #e40f13;
      letter-spacing: 0.0025em;
      margin-top: 8px;
      display: none;
      align-items: center;

      &__icon {
        width: 24px;
        margin-right: 8px;
        display: inline-block;
      }
    }

    &__info {
      font-weight: 300;
      line-height: 24px;
      margin-top: 26px;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 26px;
      gap: 16px;
    }

    &__back,
    &__send {
      width: 100%;
      max-width: 198px;
      height: 40px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.005em;
      padding: 8px 24px;
      border-radius: 40px;
      transition: 0.3s ease-out;
    }

    &__back {
      color: #2f5a76;
      border: 2px solid #2f5a76;
      text-transform: capitalize;

      &:hover {
        background: #eaeef1;
        border-color: #eaeef1;
      }

      &:active {
        background: #2f5a76;
        border-color: #2f5a76;
        color: white;
      }
    }

    &__send {
      color: white;
      background: var(--b-dark-blue-gradient);

      &:hover {
        background: var(--b-dark-blue-gradient-hover);
      }

      &:active {
        background: var(--b-dark-blue-gradient-active);
      }
    }
  }
}
