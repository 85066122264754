.bwd-widget-social {
  &__wrapper {
    padding: 48px 24px;
    margin: 0 auto;
    @include desktop {
      padding: 56px 64px;
      max-width: 1440px;
    }
  }
  &__title {
    margin-bottom: 48px;

    @include desktop {
      margin-bottom: 56px;
    }
  }
  .cr__gallery {
    .loadMore {
      z-index: 0;
      width: calc(100% + 5px) !important;
      margin-left: -5px !important;
    }
  }
}
