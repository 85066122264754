.bwd-weather__overview {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  order: 1;

  &__card {
    background-image: linear-gradient(0deg, rgba(47, 90, 118, 0.72), rgba(47, 90, 118, 0.72)), linear-gradient(103.38deg, rgba(10, 45, 67, 0) 24.15%, rgba(4, 33, 51, 0.72) 84.77%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 464px;
    padding: 32px 24px;
    position: relative;
    width: 100%;

    @include tablet {
      padding: 42px;
    }
    @include desktop {
      min-height: 364px;
    }
  }

  &__image {
    border-radius: 16px;
    bottom: 0;
    filter: grayscale(100%);
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    img {
      border-radius: 16px;
    }
  }

  &__item {
    display: flex;
    font-size: 18px;
    gap: 3px;
    line-height: 24px;

    & .icon {
      height: 24px;
      width: 24px;
    }

    & .title {
      font-weight: 400;
    }
  }

  &__items {
    align-content: center;
    column-gap: min(calc(100% - 304px), 24px);
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(2, min(153px, 50%));
    grid-template-rows: repeat(4, fit-content(100%));
    justify-content: center;
    padding-top: 24px;
    row-gap: 24px;

    @include tablet {
      grid-template-columns: repeat(3, fit-content(100%));
      grid-template-rows: repeat(2, fit-content(100%));
      justify-content: space-between;
    }
    @include desktop {
      grid-template-columns: repeat(2, min(153px, 50%));
      grid-template-rows: repeat(4, fit-content(100%));
      justify-content: center;
    }
    @include desktop-large {
      grid-template-columns: repeat(3, fit-content(100%));
      grid-template-rows: repeat(2, fit-content(100%));
      justify-content: space-between;
    }

    > div {
      &:nth-child(1) {
        grid-area: 1 / 1;
      }
      &:nth-child(2) {
        grid-area: 1 / 2;
        @include tablet {
          grid-area: 2 / 1;
        }
        @include desktop {
          grid-area: 1 / 2;
        }
        @include desktop-large {
          grid-area: 2 / 1;
        }
      }
      &:nth-child(3) {
        grid-area: 2 / 1;
        @include tablet {
          grid-area: 1 / 2;
        }
        @include desktop {
          grid-area: 2 / 1;
        }
        @include desktop-large {
          grid-area: 1 / 2;
        }
      }
      &:nth-child(4) {
        grid-area: 2 / 2;
      }
      &:nth-child(5) {
        grid-area: 3 / 1 / 4 / 3;
        @include tablet {
          grid-area: 1 / 3;
        }
        @include desktop {
          grid-area: 3 / 1 / 4 / 3;
        }
        @include desktop-large {
          grid-area: 1 / 3;
        }
      }
      &:nth-child(6) {
        grid-area: 4 / 1 / 5 / 3;
        @include tablet {
          grid-area: 2 / 3;
        }
        @include desktop {
          grid-area: 4 / 1 / 5 / 3;
        }
        @include desktop-large {
          grid-area: 2 / 3;
        }
      }
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 600;
  }
}
