.bwd-destination-features {
  &__wrapper {
    padding: 20px 24px;

    @include desktop {
      max-width: 1440px;
      margin: auto;
      padding: 40px 65px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    margin: 0 auto 32px;

    @include desktop {
      max-width: 58%;
    }

    &-title {
      .cmp-title {
        &__richtext {
          font-family: "Museo Sans";
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0.0015em;
          text-align: center;
          color: #384044;

          @include desktop {
            font-size: 28px;
            line-height: 36px;
          }
        }
      }
    }

    &-description {
      .cmp-text {
        font-family: "Museo Sans";
        font-size: 16px;
        font-weight: 300;
        line-height: 19.2px;
        letter-spacing: 0.005em;
        text-align: center;
        color: #384044;
      }
    }
  }

  &__container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
    justify-content: center;

    @include tablet {
      grid-template-columns: auto auto auto;
      gap: 32px;
    }

    @include desktop {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      justify-content: center;
    }
  }
}
