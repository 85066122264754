/*  MOBILE FIRST (~ TABLET) */
.bwd-art-capt {
  display: flex;
  justify-content: center;
  &__cont {
    max-width: 1440px;
    &__img {
      //## if video
      > .wrap-art-video {
        position: relative;
        &.play {
          background-color: black;
          .c-video-responsive {
            height: auto !important;
          }
          > .art-video-overlay {
            display: none;
          }
        }
        &:not(.play) {
          .plyr__control {
            display: none !important;
          }
        }
        > .art-video {
          min-width: 380px;
          padding-left: 1px;
        }
        > .art-video-overlay {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          > .btnplay {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.3);
            color: white;
            width: 152px;
            height: 56px;
            position: absolute;
            left: 0;
            bottom: 0;
            font-family: "museo sans";
            font-size: 14px;
            gap: 16px;
            cursor: pointer;
            > .icon-video::before {
              font-size: 32px;
            }
          }
        }
      }
      //##
    }
  }
}

//#### article caption config 1
.v1.bwd-art-capt {
  text-align: center;
  padding: 24px 24px 34px;
  justify-content: center;
  display: flex;
  .bwd-art-capt {
    &__cont {
      &__txt {
        color: $ocean-70;
        font-family: "Sfizia";
        font-style: italic;
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
}
//####

//#### article caption config 2
.v2.bwd-art-capt,
.v2_b.bwd-art-capt,
.v2_video.bwd-art-capt {
  padding: 38px 0;
  .bwd-art-capt {
    &__cont {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      &__img {
        > .picture {
          height: 609px;
        }
        > .capt {
          padding: 8px 24px;
          font-weight: 400;
        }
      }
    }
  }
}
.v2_b.bwd-art-capt {
  .bwd-art-capt {
    &__cont {
      &__img {
        > .picture {
          height: 275px;
        }
      }
    }
  }
}
//####
//#### article caption config 3 and 4
.v3.bwd-art-capt,
.v4.bwd-art-capt,
.v4_video.bwd-art-capt {
  padding: 38px 0;
  .bwd-art-capt {
    &__cont {
      display: flex;
      flex-direction: column;

      &__txt {
        padding: 0 24px 38px 24px;
      }
      &__img {
        > .picture {
          height: 609px;
        }
      }
    }
  }
  &.mod--reverse {
    .bwd-art-capt {
      &__cont {
        flex-direction: column-reverse;
        &__txt {
          padding: 38px 24px 0px 24px;
        }
      }
    }
  }
}

//#### article caption config 4
.v4.bwd-art-capt,
.v4_video.bwd-art-capt {
  .bwd-art-capt {
    &__cont {
      &__txt {
        color: $ocean-70;
        font-family: "Sfizia";
        font-style: italic;
        font-size: 36px;
        line-height: 40px;
        padding-bottom: 44px;
        &::before {
          content: "";
          display: block;
          width: 64px;
          height: 2px;
          background-color: $ocean-70;
          margin-bottom: 20px;
        }
      }
      &__img {
        > .capt {
          padding: 8px 24px;
          font-weight: 400;
        }
      }
    }
  }
}
//####
//#### article caption config 5 and 6
.v5.bwd-art-capt,
.v5_video.bwd-art-capt,
.v6.bwd-art-capt,
.v6_video.bwd-art-capt {
  justify-content: center;
  display: flex;
  padding: 38px 0;
  .bwd-art-capt {
    &__cont {
      width: 100%;
      &__img {
        > .picture {
          height: 609px;
        }
        > .capt {
          padding: 8px 24px;
          font-weight: 400;
        }
      }
    }
  }
}
//####

/* DESKTOP */

@include desktop {
  .bwd-art-capt {
    &__cont {
      &__img {
        //## if video
        > .wrap-art-video {
          > .art-video {
            .c-video.barceloPlayer {
              top: 50%;
              left: 49.99%;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }
            .c-video-responsive {
              width: calc(100% - 0.5px);
            }
          }
        }
        //##
      }
    }
  }

  //#### article caption config 1
  .v1.bwd-art-capt {
    padding: 64px;
    .bwd-art-capt {
      &__cont {
        &__txt {
          font-size: 56px;
          line-height: 64px;
          max-width: 960px;
        }
      }
    }
  }
  //####

  //#### article caption config 2
  .v2.bwd-art-capt,
  .v2_b.bwd-art-capt,
  .v2_video.bwd-art-capt {
    padding: 48px 64px;
    .bwd-art-capt {
      &__cont {
        gap: 24px;
        flex-direction: row;
        width: 100%;
        &__img {
          > .picture {
            height: 752px;
          }
          > .capt {
            padding: 8px 0px 0px;
          }
        }
      }
    }
  }
  .v2_b.bwd-art-capt {
    .bwd-art-capt {
      &__cont {
        &__img {
          > .picture {
            height: 464px;
          }
        }
      }
    }
  }
  .v2_video.bwd-art-capt {
    .bwd-art-capt {
      &__cont {
        &__img {
          width: 50%;
          > .wrap-art-video {
            > .art-video {
              height: 464px;
              .c-video-responsive {
                height: 464px;
              }
            }
          }
        }
      }
    }
  }
  //####

  //#### article caption config 3 and 4
  .v3.bwd-art-capt,
  .v4.bwd-art-capt,
  .v4_video.bwd-art-capt {
    padding: 64px;
    .bwd-art-capt {
      &__cont {
        flex-direction: row;
        align-items: center;
        &__txt {
          padding-right: 135px;
          padding-left: 0;
          width: 50%;
          line-height: 24px;
        }
        &__img {
          width: 50%;
          > .picture {
            height: 644px;
          }
          > .capt {
            padding: 8px 0px 0px;
          }
        }
      }
    }
    &.mod--reverse {
      .bwd-art-capt {
        &__cont {
          flex-direction: row-reverse;
          &__txt {
            padding-right: 0px;
            padding-left: 135px;
          }
        }
      }
    }
  }
  //####
  //#### article caption config 4
  .v4.bwd-art-capt,
  .v4_video.bwd-art-capt {
    .bwd-art-capt {
      &__cont {
        &__txt {
          font-size: 56px;
          line-height: 64px;
          padding-bottom: 0;
          &::before {
            margin-bottom: 42px;
          }
        }
        &__img {
          > .capt {
            padding: 8px 0px 0px;
          }
        }
      }
    }
  }
  .v4_video.bwd-art-capt {
    .bwd-art-capt {
      &__cont {
        &__img {
          > .wrap-art-video {
            > .art-video {
              height: 464px;
              .c-video-responsive {
                height: 464px;
                .plyr__video-wrapper {
                  height: 464px;
                }
              }
            }
          }
        }
      }
    }
  }
  //####
  //#### article caption config 5 and 6
  .v5.bwd-art-capt,
  .v6.bwd-art-capt {
    padding: 48px 64px;
    display: block;
    .bwd-art-capt {
      &__cont {
        margin: auto;
        &__img {
          > .picture {
            height: 752px;
          }
          > .capt {
            padding: 8px 0px 0px;
          }
        }
      }
    }
  }
  //####
  //#### article caption config 5 video
  .v5_video.bwd-art-capt {
    padding: 48px 64px;
    .bwd-art-capt {
      &__cont {
        width: 100%;
        &__img {
          > .wrap-art-video {
            > .art-video {
              height: 751px;
              .c-video-responsive {
                height: 751px;
                .plyr__video-wrapper {
                  height: 751px;
                }
              }
            }
          }
        }
      }
    }
  }
  //####
  //#### article caption config 6
  .v6.bwd-art-capt,
  .v6_video.bwd-art-capt {
    padding: 48px 64px;
    .bwd-art-capt {
      &__cont {
        display: flex;
        justify-content: center;
        &__img {
          width: 50%;
          > .picture {
            height: 444px;
          }
        }
      }
    }
  }
  .v6_video.bwd-art-capt {
    .bwd-art-capt {
      &__cont {
        &__img {
          > .wrap-art-video {
            > .art-video {
              height: 444px;
              .c-video-responsive {
                height: 444px;
                .plyr__video-wrapper {
                  height: 444px;
                }
              }
            }
          }
        }
      }
    }
  }
  //####
}
