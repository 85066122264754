.bwd-tabs {
  &.mod--bwdtabs-rounded-cards {
    & > .cmp-tabs {
      & > div.swiper {
        & > .swiper-wrapper-mask {
          transition: all 0.33s ease-out;

          &.left {
            mask-image: linear-gradient(90deg, transparent 24px, black 70px);
            @include tablet {
              mask-image: linear-gradient(90deg, transparent 58px, black 170px);
            }
            @include desktop {
              mask-image: linear-gradient(90deg, transparent 73px, black 216px);
            }
          }

          &.right {
            mask-image: linear-gradient(90deg, black calc(100% - 70px), transparent calc(100% - 24px));
            @include tablet {
              mask-image: linear-gradient(90deg, black calc(100% - 170px), transparent calc(100% - 58px));
            }
            @include desktop {
              mask-image: linear-gradient(90deg, black calc(100% - 216px), transparent calc(100% - 73px));
            }
          }

          &.left.right {
            mask-image: linear-gradient(90deg, transparent 24px, black 70px, black calc(100% - 70px), transparent calc(100% - 24px));
            @include tablet {
              mask-image: linear-gradient(90deg, transparent 58px, black 170px, black calc(100% - 170px), transparent calc(100% - 58px));
            }
            @include desktop {
              mask-image: linear-gradient(90deg, transparent 73px, black 216px, black calc(100% - 216px), transparent calc(100% - 73px));
            }
          }
        }
        & > .swiper-button {
          &-next,
          &-prev {
            border-radius: 99999px;
            height: 44.8px;
            margin-top: -22.4px;
            transition: all 0.33s ease-out;
            width: 44.8px;

            @include desktop {
              height: 56px;
              margin-top: -28px;
              width: 56px;
            }

            &::after {
              font-size: 18px;
              left: unset;
              right: unset;

              @include desktop {
                font-size: 22.5px;
              }
            }

            &.small {
              height: 32px;
              margin-top: -16px;
              width: 32px;
              &::after {
                font-size: 14px;
              }
            }
          }
          &-prev {
            left: 7px;
            @include desktop {
              left: 9px;
            }

            &.swiper-button-disabled {
              left: -56px;
              opacity: 1;
            }
          }
          &-next {
            right: 7px;
            @include desktop {
              right: 9px;
            }

            &.swiper-button-disabled {
              opacity: 1;
              right: -56px;
            }
          }
        }
      }
    }
  }
}
