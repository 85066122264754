.bwd-hero {
  &__wrapper {
    position: relative;
    width: 100%;
    height: 900px;
    overflow: hidden;

    @include desktop {
      height: 1000px;
    }

    &.mod--animation-show {
      .bwd-hero__content {
        top: 50%;
        opacity: 0;
      }

      &--active {
        .bwd-hero__content {
          opacity: 1;
          top: 0;
        }
      }
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &--simple {
      height: 720px;
    }

    &--gradient {
      .bwd-hero__wrapper-image {
        &::after {
          content: "";
          background: linear-gradient(180deg, rgba(43, 54, 61, 0) 0%, rgba(1, 17, 27, 0.3) 83.91%);
          background-blend-mode: multiply;
          width: 100%;
          height: 100%;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 142px 58px 160px;
    z-index: 1;
    height: 100%;
    position: relative;
    transition: all 3000ms cubic-bezier(0, 0, 0, 1.15);

    @include desktop {
      align-items: flex-start;
      justify-content: flex-start;
      padding: 253px 175px 0;
      max-width: 1440px;
      margin: 0 auto;
    }

    &-title {
      color: $wedding-pink;
      margin-bottom: auto;

      @include desktop {
        margin-bottom: 24px;
      }

      .cmp-title {
        text-align: left;

        &__text {
          font-size: 18px;
          line-height: 21.94px;

          @include desktop {
            font-size: 24px;
            line-height: 29.26px;
          }
        }
      }
    }

    &-subtitle {
      color: $ocean-15;
      margin-bottom: 66px;

      @include desktop {
        max-width: 750px;
      }

      .cmp-title {
        text-align: left;

        &__subtitle,
        &__richtext {
          font-size: 43px;
          letter-spacing: 0;

          @include desktop {
            font-size: 100px;
            line-height: 96px;
          }
        }
      }
    }

    &-text {
      font-family: "Museo Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: left;
      color: white;
      padding-left: 80px;
      position: relative;
      max-width: 390px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 12px;
        width: 61px;
        height: 1px;
        background-color: #c5c5c5;

        @include desktop {
          height: 2px;
        }
      }
    }

    &--simple,
    &--simple-small {
      padding: 0 24px 224px;
      justify-content: flex-end;

      @include desktop {
        align-items: center;
        padding: 0 150px 224px;
        max-width: 1440px;
        margin: 0 auto;
      }

      .bwd-hero__content-title {
        margin-bottom: 8px;

        @include desktop {
          margin-bottom: 16px;
        }

        .cmp-title {
          text-align: center;

          &__text {
            font-size: 16px;
            line-height: 19.5px;

            @include desktop {
              font-size: 24px;
              line-height: 29.26px;
            }
          }
        }
      }

      .bwd-hero__content-subtitle {
        margin-bottom: 0;

        @include desktop {
          max-width: 1150px;
          margin-bottom: 0;
        }

        .cmp-title {
          text-align: center;

          &__subtitle,
          &__richtext {
            font-size: 54px;
            line-height: 54px;

            @include desktop {
              font-size: 100px;
              line-height: 96px;
            }
          }
        }
      }
    }

    &--simple-small {
      .bwd-hero__content-subtitle {
        .cmp-title {
          &__subtitle,
          &__richtext {
            @include desktop {
              font-size: 72px;
              line-height: 87.77px;
            }
          }
        }
      }
    }

    &--white {
      .bwd-hero__content-title {
        color: $wedding-pink;
      }

      .bwd-hero__content-subtitle {
        color: $ocean-15;
      }

      .bwd-hero__content-text {
        color: white;
      }

      &.bwd-hero__content--simple .bwd-hero__content-subtitle {
        color: white;
      }

      .bwd-hero__content-text {
        color: white;
      }
    }

    &--black {
      .bwd-hero__content-title {
        color: $ocean-70;
      }

      .bwd-hero__content-subtitle {
        color: $barcelo-black;
      }

      .bwd-hero__content-text {
        color: black;
      }

      .bwd-hero__content-text {
        &::before {
          background-color: black;
        }
      }
    }

    &--white-white {
      .bwd-hero__content-title {
        color: $ocean-15;
      }
      .bwd-hero__content-subtitle {
        color: $ocean-15;
      }
      .bwd-hero__content-text {
        color: white;
      }
      &.bwd-hero__content--simple .bwd-hero__content-subtitle {
        color: white;
      }

      .bwd-hero__content-text {
        color: white;
      }
    }
  }
}
