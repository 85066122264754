.bwd-tabs {
  &__title-wrapper {
    margin-bottom: 40px;
    gap: 16px;

    .title {
      font-family: Sfizia;
      font-size: 64px;
      line-height: 56px;
    }

    .subtitle {
      color: #757575;
      letter-spacing: 0.005em;
      text-align: center;
    }
  }
}
