.bwd-map {
  &__card-carousel {
    width: 500px;
    height: 562px;
    display: none;
    overflow: hidden;

    @include desktop {
      display: flex;
      flex-direction: column;
    }

    .swiper {
      &-container {
        width: 400px;
        height: 498px;
      }

      &-slide {
        width: 400px;
        height: 100%;
        background-color: #ffffff;
      }
    }
  }

  &__card-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: 24px;

    .card-button {
      &-prev,
      &-next {
        background: white;
        border: 1px solid $barcelo-black;
        border-radius: 50%;
        padding: 10px;
        font-size: 16px;
        width: 40px;
        height: 40px;
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
          background-color: #f0f0f0;
        }

        &.swiper-button-disabled,
        &.swiper-button-lock {
          cursor: default;
          display: block;
          opacity: 0.5;

          &:hover {
            background-color: white;
          }
        }
      }

      &-prev {
        background-image: url("/content/dam/barcelo/commons/icons/ui/arrow-left.svg");
      }

      &-next {
        background-image: url("/content/dam/barcelo/commons/icons/ui/arrow-right.svg");
      }
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin: 28px 0 0;
    width: 100%;
    max-width: 1312px;

    @include desktop {
      margin: 45px;
    }
  }

  &__country-cards {
    font-weight: 400;
  }

  &__description {
    margin-top: 18px;

    a {
      position: relative;
      margin-top: 28px;
      display: inline-block;

      @include desktop {
        margin-top: 0;
        margin-left: 24px;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 2px;
        display: block;
        width: 35%;
        height: 2px;
        background-color: $barcelo-black;
        transition: 0.3s ease-out;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }

  &__element {
    &.open {
      height: 100vh;
      max-height: 597px;
      width: 100vw;

      @include tablet {
        max-height: 797px;
      }

      @include desktop {
        width: calc(100% - 500px);
        height: 562px;
      }
    }
  }

  &__modal {
    position: absolute;
    bottom: 18px;
    justify-content: center;
    align-items: center;
    z-index: 2;
    animation: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards bwdMapOpenModal;

    @include desktop {
      top: 0;
    }

    &__close {
      display: none;
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: white;
      cursor: pointer;
      border: 1px solid #384044;
      border-radius: 40px;
      font-size: 20px;
      line-height: 20px;
      width: 40px;
      height: 40px;

      @include desktop {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }

    &__content {
      background: white;
      padding: 20px;
      border-radius: 5px;
      max-height: 430px;
      width: calc(100vw - 36px);
      max-width: 1312px;
      z-index: 1000;
      display: flex;
      padding: 0;

      @include tablet {
        max-height: 645px;
      }

      @include desktop {
        width: calc(100vw - 24px);
        height: 617px;
        max-height: initial;
      }

      @media (min-width: 1440px) {
        width: calc(100vw - 128px);
      }

      & > div {
        width: 60%;
      }

      .bwd-map-card {
        width: calc(100vw - 36px);
        height: 144px;

        @include tablet {
          height: 230px;
        }

        @include desktop {
          flex-shrink: 0;
          max-width: 500px;
          height: 100%;
          max-width: 755px;
        }

        &__swiper {
          height: 100%;
        }

        &__box {
          padding: 18px 18px 0;
          height: auto;
          max-height: 214px;
          //overflow: auto;

          @include desktop {
            margin-top: 80px;
            padding: 0 24px;
            max-width: 557px;
            max-height: 458px;
          }
        }

        // &__info {
        //   @include tablet {
        //     margin-bottom: 31px;
        //   }
        // }

        &__title {
          margin-bottom: 5px;

          @include tablet {
            margin-bottom: 8px;
          }

          @include desktop {
            font-size: 24px;
            line-height: 34px;
          }
        }

        &__location {
          margin-bottom: 12px;

          @include tablet {
            margin-bottom: 16px;
          }
        }

        &__description {
          line-clamp: initial;
          -webkit-line-clamp: initial;
          -webkit-box-orient: initial;
          text-overflow: initial;
          display: block;
          max-height: initial;

          p {
            margin-top: 12px;
            font-size: 14px;
            line-height: 20px;

            @include desktop {
              margin-top: 16px;
              font-size: 16px;
              line-height: 24px;
            }

            &:first-of-type {
              margin-top: 0;
            }
          }
        }
      }

      .column-left {
        border-right: 1px solid #ccc;
      }

      .column-right {
        overflow-y: auto;
      }

      h3 {
        margin-top: 0;
      }

      .column {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        width: 100%;

        & > article {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 430px;
          border-radius: 0px;

          @include desktop {
            flex-direction: row;
            height: auto;
          }
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 63px 0 0;
    min-height: 887px;

    @include desktop {
      justify-content: center;
      padding: 87px 0;
    }
  }

  &__zoom-controls {
    position: absolute;
    top: 10px;
    left: 55px;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;

    button {
      background-color: white;
      border: 1px solid #384044;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
      font-size: 16px;
      line-height: 16px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #384044;

      &:disabled {
        cursor: default;
        opacity: 0.5;
      }

      &:hover {
        &:not(:disabled) {
          background-color: #f0f0f0;
          opacity: 1;
        }
      }
    }
  }

  .full-width {
    height: 100vh;
    width: 100vw;
    max-height: 597px;

    @include tablet {
      max-height: 797px;
    }

    @include desktop {
      width: calc(100vw - 128px);
      height: 562px;
    }
  }

  .hidden {
    display: none;
  }

  .loading {
    animation: 1.5s shine linear infinite;
    background-color: #eee;
    background-image: linear-gradient(90deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
  }
}

// Animations
@keyframes bwdMapOpenModal {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
