//== Base
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  &.mod--header-sticky {
    overflow-x: clip;
  }
}

a {
  text-decoration: none;
}

button,
input,
optgroup,
select,
textarea {
}

hr {
}

h1,
h2,
h3,
h4,
h5,
h6 {
}

p {
}
