.bwd-article-header {
  &__wrapper {
    margin: 0 32px;
  }

  &__title {
    margin-top: 24px;
    margin-bottom: 16px;
    text-align: center;

    &-text {
      font-family: "Sfizia";
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      letter-spacing: 0.4em;
      margin: unset;
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    text-align: center;
    font-family: "Sfizia";
    font-size: 38px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.005em;

    @include desktop {
      font-size: 64px;
      line-height: 64px;
      letter-spacing: 0;
    }
  }

  &__description {
    max-width: 864px;
    margin: 0 auto 16px;
    color: #606669;

    &-text {
      font-family: "Museo Sans";
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.0015em;
      text-align: center;
    }

    &-card {
      margin-bottom: 64px;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;

    &.mod--desktop {
      display: none;
    }

    @media (min-width: 390px) {
      &.mod--desktop {
        display: block;
      }

      &.mod--mobile {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $barcelo-black;
    font-family: "Museo Sans";
    font-size: 12px;
    font-weight: 300;

    @media (max-width: 336px) {
      .bwd-article-header__writer,
      .bwd-article-header__date {
        flex: 1;
      }

      .bwd-article-header__reading-info {
        flex: 2.5;
      }
    }

    &--mod-love-stories {
      .bwd-article-header__location,
      .bwd-article-header__event {
        color: #606669;
        line-height: 16px;
        letter-spacing: 0.004em;
        display: flex;
        align-items: center;
        font-weight: 400;
      }
    }
  }

  &__writer {
    margin-right: 25px;
    font-weight: 400;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      right: -16px;
      height: 100%;
      width: 1px;
      background: #d7d9da;
    }
  }

  &__date {
    margin-right: 17px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      right: -8px;
      height: 100%;
      width: 1px;
      background: #d7d9da;
    }
  }

  &__writer,
  &__date {
    line-height: 16px;
    text-align: center;
  }

  &__reading {
    &-info {
      display: flex;
      align-items: center;
      gap: 4px;

      @media (max-width: 430px) {
        flex-wrap: wrap;
        line-height: 14px;
      }
    }

    &-text {
      letter-spacing: 0.004em;
    }

    &-time {
      font-weight: 400;
    }
  }

  &__location {
    margin-right: 49px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      right: -24px;
      height: 100%;
      width: 1px;
      background: #d7d9da;
    }
  }

  &__image {
    margin-top: 24px;
    height: 248px;
  }

  &__card {
    position: relative;
    width: 100%;
    max-width: 320px;
    height: fit-content;

    @include desktop {
      height: 568px;
      max-width: 421px;
    }
    &-image {
      position: relative;
      width: 100%;
      height: auto;

      &-background {
        width: 100%;
        height: 230px;
        margin-bottom: 20px;

        @include desktop {
          height: 308px;
          margin-bottom: 24px;
        }
      }
      &-tags {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        overflow: auto;
        justify-content: initial;
        gap: 8px;
        padding: 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &::-webkit-scrollbar {
          width: 0;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $barcelo-black;
          border-radius: 10px;
          cursor: pointer;

          &:hover {
            background-color: #384044e8;
          }
        }

        &-tag {
          font-family: "Museo Sans";
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.004em;
          text-align: center;
          padding: 4px 8px;
          border-radius: 8px;
          color: #384044;
          background-color: #eaeef1;
        }
      }
    }

    &-tag {
      display: inline-block;
      border-radius: 8px;
      color: $barcelo-black;
      background-color: #eaeef1;
      font-family: "Museo Sans";
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.004em;

      &-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        position: absolute;
        top: 190px;
        left: 16px;

        @include desktop {
          gap: 16px;
          top: 268px;
        }
      }
    }

    &-content {
      padding: 0 16px 20px;

      @include desktop {
        padding: 0 24px 24px;
      }
    }

    &-location {
      display: flex;
      align-items: center;
      margin: 12px 0 12px 8px;
    }

    &__card {
      position: relative;
      width: 100%;
      max-width: 320px;
      height: fit-content;

      @include desktop {
        height: 568px;
        max-width: 421px;
      }

      &-background {
        width: 100%;
        height: 308px;

        @include desktop {
          height: 308px;
        }
      }

      &-tag {
        display: inline-block;
        border-radius: 8px;
        color: $barcelo-black;
        background-color: #eaeef1;
        font-family: "Museo Sans";
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.004em;

        &-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          position: absolute;
          top: 190px;
          left: 16px;

          @include desktop {
            gap: 16px;
            top: 268px;
          }
        }
      }

      &-content {
        padding: 24px;

        @include desktop {
          height: 260px;
        }
      }

      &-location {
        display: flex;
        align-items: center;
        margin: 12px 0 12px 8px;
      }

      &-hotel {
        font-family: "Museo Sans";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.004em;
        color: #606669;
      }

      &-title {
        font-family: "Museo Sans";
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
        letter-spacing: 0.0015em;
        width: fit-content;
        text-align: left;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;

        * {
          display: inline;
          font-style: normal;
          font-weight: 600;
        }

        @include desktop {
          font-size: 28px;
          line-height: 36px;
          height: 36px;
        }
      }

      &-description {
        margin-bottom: 16px;

        &-text {
          font-family: "Museo Sans";
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          letter-spacing: 0.005em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          line-clamp: 6;
          -webkit-box-orient: vertical;

          @include desktop {
            height: 96px;
            -webkit-line-clamp: 4;
            line-clamp: 4;
          }
        }
      }

      &-subtitle {
        min-height: 8px;
        margin: 8px 0;

        @include desktop {
          min-height: 24px;
        }
      }
    }

    &-description {
      margin-bottom: 16px;

      &-text {
        font-family: "Museo Sans";
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 0.005em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        -webkit-box-orient: vertical;

        @include desktop {
          height: 96px;
          font-size: 16px;
          line-height: 24px;
          -webkit-line-clamp: 4;
          line-clamp: 4;
        }
      }
    }

    &-subtitle {
      min-height: 8px;
      margin: 8px 0;
    }

    &-cta {
      position: relative;
      font-family: "Museo Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;

      a {
        text-decoration: none;
      }

      &::after {
        content: "";
        background: #f9a932;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 24px;
        height: 1px;
      }
    }
  }

  @include desktop {
    &:has(&__card) {
      width: 421px;
    }

    &__wrapper {
      margin: 0 64px;
    }

    &__title {
      margin-top: 48px;
    }

    &__description {
      &-text {
        font-size: 20px;
      }
    }

    &__image {
      margin-top: 48px;
      height: 496px;
    }

    &__content {
      margin-bottom: 48px;
    }

    &__writer,
    &__date,
    &__location {
      &::after {
        height: 10px;
      }
    }
  }
}
