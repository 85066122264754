.bwd-packages-banner {
  &__banners {
    padding-top: 48px;
  }

  &__banners > &__swiper {
    margin: 0;
    padding: 0;

    & > .swiper-wrapper {
      & > .swiper-slide {
        background-color: white;
        display: flex;
        flex-direction: column;
        height: auto;
        user-select: none;
        width: auto;

        &:first-child {
          margin-left: 24px;
          @include desktop {
            margin-left: max(64px, calc(50vw - 656px));
          }
        }

        &:last-child {
          margin-right: 24px;
          @include desktop {
            margin-right: max(64px, calc(50vw - 656px));
          }
        }
      }
    }

    .swiper {
      &-nav {
        &-btn {
          align-items: center;
          background-color: white;
          border: 1px solid #384044;
          border-radius: 32px;
          cursor: pointer;
          display: flex;
          font-family: barcelo-ui-kit;
          font-size: 12px;
          height: 32px;
          justify-content: center;
          line-height: 15px;
          user-select: none;
          width: 32px;

          &.swiper-button-disabled {
            cursor: default;
            opacity: 0.5;
          }
        }

        &-wrapper {
          display: flex;
          gap: 24px;
          justify-content: center;
          padding-top: 24px;
        }
      }
    }
  }

  &__root {
    background-color: #d9d3cf;
    padding: 56px 0;

    @include desktop {
      padding: 72px 0;
    }

    .c-cta {
      font-weight: 600;
      height: auto;
      letter-spacing: 0.005em;
      line-height: 30px;
      margin: 0;
      min-width: 280px;
      padding: 8px 32px;
    }
    .pad-x {
      padding-left: 24px;
      padding-right: 24px;
      @include desktop {
        padding-left: 64px;
        padding-right: 64px;
      }
    }
    .text-center {
      text-align: center;
    }
  }

  &__subtitle {
    font-weight: 400;
    letter-spacing: 0.005em;
    margin-bottom: 24px;
  }

  &__title {
    .cmp-title__subtitle {
      font-size: 48px;
      line-height: 56px;
      margin: 24px 0;
    }

    .cmp-title__text {
      letter-spacing: 0.4em;
      padding-bottom: 24px;
      position: relative;
      text-indent: 0.2em;

      &::after {
        background-color: #384044;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: calc(50% - 32px);
        position: absolute;
        width: 64px;
      }
    }
  }
}
