.bwd-features-banner {
  &__wrapper {
    padding: 32px 35px;
    @include desktop {
      max-width: 1440px;
      padding: 32px 64px 80px;
      margin: 0 auto;
    }
  }

  &__title {
    margin-bottom: 64px;
    .cmp-title {
      &__richtext {
        font-family: "Sfizia";
        font-size: 16px;
        font-weight: 400;
        line-height: 19.5px;
        letter-spacing: 0.4em;
        text-align: center;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    @include desktop {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      gap: 64px;
    }
  }
}
