.bwd-articles-list {
  &__wrapper {
    padding: 0;

    @include desktop {
      padding: 56px 64px;
    }
  }

  &__article {
    &-wrapper {
      display: none;
      flex-wrap: wrap;
      gap: 24px;
      margin: 16px auto 0;
      justify-content: center;

      &.mod--active {
        display: flex;
        @include desktop {
          display: grid;
        }
      }

      @include desktop {
        grid-template-columns: repeat(3, 1fr);
        width: fit-content;
        margin: 64px auto 0;
      }
    }
  }

  &__title {
    margin-top: 56px;
    .cmp-title__text {
      color: #a2a2a2;
    }
  }

  &__description {
    margin: 24px auto 0;
    max-width: 716px;

    .cmp-text {
      font-family: "Museo Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: center;
      color: $grey-75;
    }

    @include mobile-only {
      margin: 24px 12px 0;
    }
  }

  &__tabs {
    margin-top: 24px;

    &.mod--bwd-article-list-rounded-cards {
      & > .tabs-wrapper {
        padding: 24px 0;

        @include desktop {
          margin: 0 auto;
          max-width: 1440px;
          padding: 34.5px 0;
        }

        & > .bwd-articles-list__tablist {
          display: block;
          margin: 0 0 24px 0;
          overflow-x: hidden;
          padding: 0 24px;

          @include desktop {
            padding: 0;
          }

          .bwd-articles-list__tab {
            align-items: center;
            background: unset;
            background-color: #f5f5f6;
            border: 1px solid #d7d9da;
            border-radius: 16px;
            color: #384044;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            font-size: 16px;
            font-weight: 400;
            gap: 3px;
            height: auto;
            justify-content: center;
            letter-spacing: 0.005em;
            line-height: 24px;
            margin: 0 4px;
            min-width: 172px;
            padding: 6px 12px;
            transition: all 0.5s ease-out;
            user-select: none;
            white-space: normal;
            width: 172px;
            text-align: center;

            @include desktop {
              font-size: 18px;
              margin: 0 12px;
              min-width: 212px;
              padding: 11.5px 24px;
              width: 212px;
            }

            &:hover {
              background: unset;
              background-color: white;
              border: 1px solid #f5f5f6;
              box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
            }

            &.bwd-articles-list__tab--active {
              background-color: white;
              cursor: default;

              &:hover {
                border: 1px solid #d7d9da;
                box-shadow: none;
              }
            }

            & p {
              font-family: "Sfizia";
              font-size: 11px;
              letter-spacing: 0.3em;
              line-height: 13.41px;

              @include desktop {
                font-size: 13px;
                line-height: 15.85px;
              }
            }
          }
        }
      }
    }
  }

  &__see-more {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    :first-child {
      font-family: "Museo Sans";
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.005em;
      color: #ffffff;
      margin: auto;
      padding: 8px 32px;
      border-radius: 100px;
      background: linear-gradient(90deg, #30a2bf 0%, #2f5a76 100%);
      text-decoration: none;
    }
  }
}
