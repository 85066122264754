.bwd-page {
  header.xpf_reference_header {
    background-color: #2a2a2a80;

    &.c-container-collapsed {
      .header-bg-blur {
        backdrop-filter: blur(4px);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        right: 0px;
        z-index: -1;
      }
    }

    > .web-fragment > .root > .aem-Grid > .generic-container {
      background-color: transparent;
    }

    .header {
      @include desktop {
        height: auto;
      }
    }

    &.mod--header-sticky {
      left: 0;
      position: sticky;
      right: 0;
      top: 0;
      z-index: 9;

      &.c-container-collapsed {
        position: fixed;
      }

      .c-megamenu__submenu.fullCol {
        @include desktop {
          overflow: auto;
          max-height: calc(100vh - 100px);
        }
      }

      .c-go-up-btn {
        top: calc(57.5vh + 64px + 30px);
      }
    }
  }
}
