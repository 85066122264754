.bwd-weather {
  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 12px;
    text-align: center;
  }
  &__title {
    h1 {
      margin: 12px !important;
    }
    p {
      font-size: 54px;
      line-height: 64px;
      margin: 12px;
    }
    .cmp-title__subtitle {
      margin: 0;

      &::before {
        border-bottom: 1px solid #384044;
        content: "";
        display: block;
        margin: 12px auto;
        width: 64px;
      }
    }
  }
}
