.bwd-services-list {
  position: relative;
  z-index: 0;
  min-height: 887px;

  &__wrapper {
    margin: 0 24px 24px;
  }

  &__background {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    filter: grayscale(1);
    z-index: $z-underground;
    height: 896px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 896px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.85) 0 35%, white 75% 100%);
    }
  }

  &__title {
    padding-top: 54px;
    padding-bottom: 24px;

    .cmp-title {
      &__subtitle {
        font-size: 40px;
        letter-spacing: 0.005em;
      }
    }

    .cmp-title__text {
      color: #0f1418;
    }
  }

  &__text {
    margin-bottom: 32px;
    text-align: center;
    color: $grey-75;

    .cmp-text {
      font-family: "Museo Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      letter-spacing: 0.005em;
    }
  }

  &__cards {
    display: flex;
    margin: auto;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 1140px;
  }

  @include tablet {
    &__cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include desktop {
    &__wrapper {
      margin: 0 147.5px;
    }

    &__title {
      padding-top: 95px;

      .cmp-title {
        &__subtitle {
          font-size: 64px;
          line-height: 56px;
        }
      }
    }

    &__text {
      margin-bottom: 113px;
    }
  }

  @media (min-width: 1200px) {
    &__background {
      height: 581px;

      &::after {
        height: 581px;
      }
    }

    &__cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    }
  }
}
