.bwd-weather__forecast {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  order: 2;

  &__card {
    align-items: center;
    border: 1px solid #d7d9da;
    display: flex;
    flex-direction: column;
    max-height: 800px;
    padding: 24px;

    @include desktop {
      max-height: none;
    }

    &.broken-clouds {
      background: radial-gradient(65.3% 36.48% at 2.48% 35.06%, rgba(146, 248, 255, 0.56) 0%, rgba(50, 249, 249, 0) 100%), radial-gradient(57.18% 33.15% at 123.19% 36.98%, rgba(50, 142, 249, 0.7) 0%, rgba(50, 249, 249, 0) 99.41%);
    }
    &.clear-sky-day {
      background: radial-gradient(71.13% 39.51% at 0% 48.77%, rgba(249, 169, 50, 0.6) 0%, rgba(249, 169, 50, 0) 99.41%);
    }
    &.clear-sky-night {
      background: radial-gradient(50.51% 26.24% at 10.76% 50%, rgba(249, 169, 50, 0.7) 0%, rgba(249, 169, 50, 0) 99.41%);
    }
    &.few-clouds-day {
      background: radial-gradient(64.1% 35.49% at -16.77% 51.85%, rgba(50, 237, 249, 0.56) 0%, rgba(50, 249, 249, 0) 100%), radial-gradient(156.21% 87.84% at 142.75% 17.04%, rgba(249, 169, 50, 0.7) 0%, rgba(249, 169, 50, 0) 99.41%);
    }
    &.few-clouds-night {
      background: radial-gradient(64.1% 35.49% at -16.77% 51.85%, rgba(50, 237, 249, 0.56) 0%, rgba(50, 249, 249, 0) 100%), radial-gradient(156.21% 87.84% at 142.75% 17.04%, rgba(249, 169, 50, 0.7) 0%, rgba(249, 169, 50, 0) 99.41%);
    }
    &.mist {
      background: radial-gradient(122.41% 71.85% at 111.59% -16.79%, #93a5bb 0%, rgba(162, 250, 250, 0) 99.41%), radial-gradient(82.52% 44.94% at -12.63% 40.25%, #93a5bb 0%, rgba(162, 250, 250, 0) 99.41%);
    }
    &.rain-day {
      background: radial-gradient(63.62% 37.53% at -11.9% 43.33%, rgba(249, 169, 50, 0.5) 0%, rgba(249, 169, 50, 0) 99.41%), radial-gradient(77.42% 51.05% at 102.28% -11.91%, rgba(50, 142, 249, 0.7) 0%, rgba(50, 249, 249, 0) 99.41%);
    }
    &.rain-night {
      background: radial-gradient(63.62% 37.53% at -11.9% 43.33%, rgba(249, 169, 50, 0.5) 0%, rgba(249, 169, 50, 0) 99.41%), radial-gradient(77.42% 51.05% at 102.28% -11.91%, rgba(50, 142, 249, 0.7) 0%, rgba(50, 249, 249, 0) 99.41%);
    }
    &.scattered-clouds {
      background: radial-gradient(67.62% 36.73% at 90.99% 4.88%, rgba(146, 248, 255, 0.56) 0%, rgba(50, 249, 249, 0) 100%);
    }
    &.shower-rain {
      background: radial-gradient(67.74% 38.52% at 7.45% 37.72%, rgba(50, 142, 249, 0.5) 0%, rgba(50, 249, 249, 0) 99.41%), radial-gradient(114.8% 67.87% at 42.13% -22.1%, rgba(50, 142, 249, 0.7) 0%, rgba(50, 249, 249, 0) 99.41%);
    }
    &.snow {
      background:
        radial-gradient(139.08% 80.99% at 60.14% -23.33%, rgba(50, 142, 249, 0.7) 0%, rgba(50, 249, 249, 0) 99.41%), radial-gradient(62.81% 34.07% at 47.93% -18.95%, rgba(14, 99, 198, 0.7) 0%, rgba(50, 153, 249, 0) 99.41%),
        radial-gradient(45.65% 33.27% at 103% 7.84%, rgba(50, 142, 249, 0.7) 0%, rgba(50, 249, 249, 0) 99.41%);
    }
    &.thunderstorm {
      background:
        radial-gradient(139.08% 80.99% at 60.14% -23.33%, rgba(50, 142, 249, 0.7) 0%, rgba(50, 249, 249, 0) 99.41%), radial-gradient(62.81% 34.07% at 47.93% -18.95%, rgba(14, 99, 198, 0.7) 0%, rgba(50, 153, 249, 0) 99.41%),
        radial-gradient(68.86% 43.21% at -34.58% 45.56%, rgba(14, 99, 198, 0.7) 0%, rgba(50, 153, 249, 0) 99.41%), radial-gradient(45.65% 33.27% at 103% 7.84%, rgba(50, 142, 249, 0.7) 0%, rgba(50, 249, 249, 0) 99.41%);
    }
  }

  &__buttons {
    margin: 36px 0 40px 0;
    text-align: center;
  }

  &__description {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 27px;
    text-transform: uppercase;
  }

  &__icon {
    display: block;
    height: 122px;
    margin: 8px 0 24px 0;
    width: 122px;
  }

  &__nextdays {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 24px;
    width: 100%;

    &::-webkit-scrollbar {
      margin-left: 16px;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #2f5a76;
      border-radius: 8px;
    }

    & > div {
      border-bottom: 1px solid #afb3b4;
      display: flex;
      flex-wrap: wrap;
      font-size: 18px;
      gap: 4px;
      padding: 16px 0;
    }

    .day {
      flex-grow: 1;
    }
    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      width: 100%;
    }
    .temperature {
      border-left: 1px solid #384044;
      text-align: end;
      width: 60px;
    }
  }

  &__stat {
    display: flex;
    margin: 8px 0;
    width: 100%;

    & > p {
      font-size: 18px;
      font-weight: 400;
      padding: 0 12px;
      width: 50%;
    }

    & > .left {
      border-right: 1px solid #384044;
      font-size: 16px;
      font-weight: 300;
      text-align: end;
    }
  }

  &__temperature {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    margin-bottom: 4px;
  }
}
