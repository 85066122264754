.bwd-article-swiper {
  &__wrapper {
    height: 705px;

    @include desktop {
      height: 718px;
    }
  }

  &__swiper {
    position: relative;
    height: 100%;
    &-item {
      position: relative;
      height: 100%;
      display: flex;
      align-items: end;

      @include desktop {
        align-items: center;
        justify-content: end;
      }

      &__background {
        position: absolute;
        width: 100%;
        z-index: -1;
        height: 100%;
      }

      &__info {
        width: 100%;
        margin: 24px;
        padding: 24px;
        background-color: #f5f5f6;

        @include desktop {
          width: 41%;
          padding: 48px 24px;
          margin: 32px 0;
        }

        &-title {
          margin-bottom: 8px;

          &__title {
            font-family: "Sfizia";
            font-size: 16px;
            font-weight: 400;
            line-height: 19.5px;
            letter-spacing: 0.4em;
            color: #0f1418;
            margin-bottom: 12px;
            text-align: center;

            @include desktop {
              text-align: left;
            }
          }

          &__subtitle {
            font-family: "Sfizia";
            font-size: 40px;
            font-weight: 400;
            line-height: 40px;
            color: #384044;
            text-align: center;

            @include desktop {
              font-size: 64px;
              line-height: 69px;
              text-align: left;
            }
          }
        }

        &-hotel {
          display: flex;
          gap: 4px;
          align-content: center;
          justify-content: center;
          margin-bottom: 16px;

          @include desktop {
            justify-content: initial;
          }

          &__icon {
            width: 16px;
            height: 16px;
          }

          &__text {
            font-family: "Museo Sans";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.004em;
            text-align: left;
            color: #606669;
          }
        }

        &-author {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          gap: 8px;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;

          @include desktop {
            justify-content: initial;
            align-items: initial;
          }

          &__name {
            font-family: "Museo Sans";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.004em;
            text-align: left;
            color: #606669;
          }

          &__date {
            font-family: "Museo Sans";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.004em;
            text-align: left;
            color: #606669;
          }

          &__time {
            display: flex;
            gap: 4px;
            align-content: center;

            &-icon {
              width: 16px;
              height: 16px;
            }

            &-text {
              font-family: "Museo Sans";
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.004em;
              text-align: left;
              color: #606669;
            }
          }
        }

        &-tags {
          display: flex;
          justify-content: center;
          gap: 8px;
          margin-bottom: 16px;
          flex-wrap: wrap;

          @include desktop {
            justify-content: initial;
          }

          &__tag {
            font-family: "Museo Sans";
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.004em;
            text-align: center;
            padding: 4px 8px;
            border-radius: 8px;
            color: #384044;
            background-color: #eaeef1;
          }
        }

        &-text {
          font-family: "Museo Sans";
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: 0.005em;
          text-align: center;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp: 4;
          margin-bottom: 16px;

          @include desktop {
            text-align: left;
          }
        }

        &-link {
          text-align: center;
          margin-bottom: 16px;
          @include desktop {
            text-align: left;
          }
          &__button {
            display: inline;
            &.mod--underline-small {
              &::before {
                content: "";
                width: 25%;
              }

              &:hover {
                &::before {
                  content: "";
                  width: 100%;
                }
              }
            }

            .c-cta {
              font-size: 16px;
              line-height: 24px;
              color: #384044;
              font-weight: 400;
            }
          }
        }
      }
    }

    &-navigations {
      display: flex;
      gap: 24px;
      justify-content: center;
      margin: 0 !important;

      @include desktop {
        justify-content: initial;
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 40px;
        height: 40px;
        position: relative;
        top: unset;
        right: unset;
        left: unset;
        bottom: unset;
        border-radius: 99999px;
        background-color: transparent;
        border: 1px solid #384044;
        margin: 0 !important;
        &::after {
          content: "";
          display: none;
        }

        &::before {
          color: #384044;
          font-weight: 600;
        }
      }

      .swiper-button-prev {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }
}
