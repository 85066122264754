.bwd-comparator-card {
  @include desktop {
    width: calc(320px - 16px);
  }

  position: relative;

  &__swiper {
    height: 164px;

    .swiper-pagination {
      &-bullets {
        bottom: 15px !important;
      }

      &-bullet {
        background-color: #fff !important;
        height: 6px !important;
        border-radius: 100px !important;
        margin: 0 4px !important;
        border: initial !important;

        &-active {
          width: 20px !important;
        }
      }
    }
  }

  &__info {
    padding: 16px 8px;
    display: flex;
    flex-direction: column;

    @include desktop {
      height: 312px;
    }

    &-name {
      font-family: "Museo Sans";
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.0015em;
      text-align: left;
      color: #384044;
      margin-bottom: 8px;
    }

    &-country {
      display: flex;
      font-family: "Museo Sans";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.005em;
      align-items: center;
      margin-bottom: 8px;

      &__icon {
        margin-right: 4px;
        font-size: 24px;
      }
    }

    &-ratings {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      &__tripadvisor {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &-rating {
          font-family: "Museo Sans";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.005em;
        }

        &-icon {
          font-size: 24px;
          margin-right: 4px;
        }

        .ta__social {
          width: 100%;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          letter-spacing: 0.004em;
        }
      }

      &__tag {
        height: fit-content;
        display: flex;
        align-items: center;
        background-color: #eaeef1;
        padding: 4px 8px;
        font-family: "Museo Sans";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.004em;
        border-radius: 8px;

        &-icon {
          border: 1px solid #384044;
          font-size: 16px;
          margin-right: 4px;
        }
      }
    }

    &-highlights {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 12px;
      padding: 8px 0;

      @include desktop {
        overflow: auto;
      }

      &__item {
        font-family: "Museo Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.0025em;
        color: #384044;
      }
    }

    &-button {
      padding-top: 12px;
      border-top: 1px solid #d7d9da;
      margin-top: auto;

      .c-cta {
        width: calc(100% - 32px);
        margin: 0 16px;
      }
    }
  }

  .mod--btn-over-image-top-right {
    z-index: 2;
  }

  &__modal {
    background-color: #00000066;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: $z-modal;

    &.mod--open {
      display: block;
    }

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 450px;
      width: 1000px;
      padding: 32px 16px;
      max-width: calc(100vw - 48px);
      max-height: calc(100vh - 48px);
      border-radius: 16px;
      background-color: white;
      display: flex;
      flex-direction: column;

      @include desktop {
        height: 650px;
        padding: 32px;
      }
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      color: $barcelo-black;
      font-size: 14px;

      @include desktop {
        top: 32px;
        right: 32px;
      }
    }

    &__title {
      font-weight: 600;
      color: $barcelo-black;
      text-align: center;
      @include ellipsis-multiline(20px, 24px, 2);

      @include desktop {
        @include ellipsis-multiline(24px, 34px, 1);
      }
    }

    &__swiper {
      height: calc(100% - 12px);
      width: 100%;
      margin-top: 12px;

      @include desktop {
        margin-top: 24px;
        height: calc(100% - 24px);
      }

      .swiper-pagination {
        &-bullets {
          bottom: 15px !important;
        }

        &-bullet {
          background-color: #fff !important;
          height: 6px !important;
          border-radius: 100px !important;
          margin: 0 4px !important;
          border: initial !important;

          &-active {
            width: 20px !important;
          }
        }
      }

      .swiper-button-prev {
        left: 0 !important;

        @include desktop {
          left: 24px !important;
        }
      }

      .swiper-button-next {
        right: 0 !important;

        @include desktop {
          right: 24px !important;
        }
      }
    }
  }
}
