// MIXINS

// Media Queries
@mixin mobile-only {
  @media (min-width: 0px) and (max-width: #{$tablet-min}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-min}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-max}) {
    @content;
  }
}

// Extra visible component on author. ex: @include author-active-preview(greenyellow, "Separator");
@mixin author-active-preview($color, $text) {
  border: 1px dashed $color;

  &:after {
    content: $text;
    padding: 0 2px 1px 4px;
    transform: translate(2px, 2px);
    font-size: 9px;
    font-style: normal;
    font-family: Arial, sans-serif;
    line-height: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    background: $color;
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: #ffffff;
  }
}

// Multiline Clamp
@mixin ellipsis-multiline($font-size, $line-height, $line-clamp) {
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: $line-height * $line-clamp + $line-clamp;
}
