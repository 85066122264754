.bwd-navigation-menu {
  &__wrapper {
    padding: 28px 0;
    display: none;

    @include desktop {
      display: block;
    }
  }

  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    text-align: center;
    color: $white;
  }
}
