.bwd-video {
  .c-video--title {
    font-family: Sfizia;
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 64px;
    margin-top: 16px;
  }
}
