.bwd-ratings-banner {
  &__wrapper {
    background-color: $grey-light;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 16px;
  }

  &__text {
    position: relative;
    margin-bottom: 48px;

    .cmp-text {
      font-family: "Sfizia";
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      letter-spacing: 0.4em;
      text-align: center;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -24px;
      height: 1px;
      width: 64px;
      border-bottom: 1px solid $barcelo-black;
    }
  }

  &__number {
    font-family: "Sfizia";
    font-size: 120px;
    font-weight: 400;
    line-height: 120px;
    margin-bottom: 24px;
  }

  &__stars {
    display: flex;
    justify-content: start;
    overflow: hidden;
    width: 264px;
    margin-bottom: 24px;
    gap: 16px;

    .bwd-ratings-banner__star {
      min-width: 34.3px;
      min-height: 34.3px;

      &-block {
        overflow: hidden;
        width: fit-content;
        height: fit-content;
      }
    }
  }

  &__description {
    font-family: "Museo Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: center;
  }

  @include desktop {
    &__wrapper {
      padding: 64px 175px;
    }
  }
}
