.bwd-gallery-card {
  &__image {
    height: 224px;
    margin-bottom: 16px;
    @include desktop {
      height: 320px;
    }
  }

  &__content {
    &-title {
      font-family: "Museo Sans";
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.0015em;
      text-align: left;
      color: #fff;
      margin-bottom: 8px;
    }

    &-description {
      font-family: "Museo Sans";
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.005em;
      text-align: left;
      color: #fff;
    }
  }
}
