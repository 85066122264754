.bwd-article-share {
  &__btn {
    display: flex;
    gap: 8px;

    & img {
      height: 24px;
      width: 24px;
    }

    &__text {
      line-height: 24px;
      letter-spacing: 0.005em;
      position: relative;

      &::after {
        background-color: #30a2bf;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        transition: all 0.3s ease-out;
        width: 24px;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
