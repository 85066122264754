.bwd-gallery {
  &__banner {
    position: relative;
    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.7) 70%);
      }
    }

    &-info {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      padding: 45.5px 24px;
      z-index: $z-mask;

      @include desktop {
        width: calc(100% - 40%);
        margin: 0 auto;
        padding: 101.5px 0;
      }

      &__title {
        color: #ffffff;

        .cmp-title {
          &__subtitle {
            margin-top: 16px;
            font-size: 40px;

            @include desktop {
              font-size: 64px;
              line-height: 56px;
            }
          }
        }
      }

      &__description {
        color: #ffffff;
        text-align: center;
        font-weight: 300;
      }

      &__label {
        color: #ffffff;
        text-align: center;
        font-weight: 400;
      }
    }
  }

  &__carousel {
    padding: 24px 0px 54px 16px !important;

    @include desktop {
      padding: 24px 0px 64px 64px !important;
      max-width: 1440px;
    }
  }
}
