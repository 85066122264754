.bwd-weather__barchart {
  display: flex;
  overflow: auto;
  user-select: none;
  margin: 0 25px;
  padding: 34px 0 8px 0;
  position: relative;

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2f5a76;
    border-radius: 8px;
  }

  &__axis {
    background-color: white;
    bottom: 0;
    left: 0;
    position: sticky;
    top: 0;
    z-index: 2;

    & > div {
      border-right: 1px solid #afb3b4;
    }

    &__margin {
      border-left: 1px solid #afb3b4;
      position: relative;

      & > div {
        border-top: 1px solid #afb3b4;
        left: 0;
        position: absolute;
        width: 2600%;
      }
    }
  }

  &__bar {
    background: rgb(47, 90, 118);
    background: linear-gradient(180deg, rgba(47, 90, 118, 1) 5%, rgba(48, 162, 191, 1) 95%);
    border-radius: 16px 16px 0 0;
    transition: all 0.66s ease-in-out;
    width: 16px;

    &__wrapper {
      align-items: center;
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 1;
      justify-content: flex-start;
      z-index: 1;
    }
  }

  &__bottom__border {
    border-bottom: 1px solid #afb3b4;
  }

  &__column {
    display: flex;
    flex-direction: column;
    min-height: 180px;
    text-align: center;

    & p {
      padding-top: 0.75em;
    }
    & span {
      bottom: 0.5em;
      height: 0;
      overflow: visible;
      position: relative;
    }
  }

  &__index {
    align-items: flex-end;
    display: flex;
    position: relative;
    width: 100%;

    & p {
      margin-bottom: -0.75em;
      padding-right: 8px;
      text-align: end;
      width: 100%;
    }
  }

  &__leftmost__indicator {
    left: -1.6px;
    text-align: start;
  }

  &__w1 {
    min-width: 21px;
    width: calc((100% * 1 / 27) - 0.3px);
  }

  &__w2 {
    min-width: 42px;
    width: calc(100% * 2 / 27);
  }

  &__w3 {
    min-width: 63px;
    width: calc(100% * 3 / 27);
  }
}

.bwd-weather__historic {
  display: flex;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  flex-direction: column;
  order: 3;

  & > div {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 0;
  }

  &__buttons {
    text-align: center;
    & > button {
      margin-bottom: 8px;
    }
  }
}
