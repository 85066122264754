// Colores

$white: #ffffff;
$grey-light: #f5f5f6;
$grey-10: #f3ede8;
$grey-25: #e0e0e0;
$grey-50: #d9d3cf;
$grey-75: #606669;

$ocean-15: #e0e6ea;
$ocean-70: #2f5a76;

$light-orange: #ffcb96;
$wedding-orange: #f9a932;

$wedding-pink: #c52f88;

$barcelo-black: #384044;
$solid-black: #000a0e;
