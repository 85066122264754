.bwd-swiper {
  &__wrapper {
    position: relative;
    margin: auto;
    width: calc(100% - 24px);
    overflow: hidden;

    @include desktop {
      width: calc(100% - 128px);
      max-width: calc(1440px - 128px);
    }

    .swiper {
      &-slide {
        width: 100%;
        height: 360px;
      }

      &-pagination {
        &-bullet {
          background-color: #fff !important;
          height: 6px !important;
          border-radius: 100px !important;
          margin: 0 4px !important;
          border: initial !important;

          &-active {
            width: 20px !important;
          }
        }

        &-bullets {
          bottom: 24px !important;
        }
      }
    }
  }
}
