.bwd-package-detail {
  &__wrapper {
    padding: 16px;

    @include desktop {
      max-width: 1440px;
      margin: auto;
    }
  }

  &__back {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;

    .icon-simple-arrow {
      font-size: 24px;
      transform: rotate(180deg);
    }
  }

  &__main {
    margin-top: 24px;

    @include desktop {
      margin-top: 48px;
    }
    &__header {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @include tablet {
        flex-direction: initial;
        align-items: initial;
      }
      @include desktop {
        flex-wrap: initial;
        justify-content: flex-end;
      }
    }

    &__title {
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: 0.015em;

      align-content: center;

      @include tablet {
        margin-right: auto;
      }

      @include desktop {
        font-size: 36px;
        line-height: 42px;
        letter-spacing: 0.025em;
        margin-right: auto;
      }
    }

    &__content {
      display: flex;
      // flex-wrap: wrap;
      // gap: 15px 24px;
      // margin: 16px 0 31px;
      gap: 15px 30px;
      margin: 0 0 31px;
      @include tablet {
        flex-wrap: wrap;
        gap: 15px 24px;
        margin: 16px 0 31px;
      }
      @include desktop {
        flex-wrap: nowrap;
        gap: initial;
        margin: 24px 0 32px;
      }
    }

    &__resort {
      position: relative;
      padding: 24px 0 16px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d7d9da;
      width: 100%;

      @include desktop {
        border-bottom: initial;
        padding: 0 0 40px;
      }

      &-image {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        object-fit: cover;
        overflow: hidden;
        flex-shrink: 0;

        @include desktop {
          width: 48px;
          height: 48px;
        }
      }

      &-wrapper {
        margin-left: 8px;
        display: flex;
        flex-direction: column;
      }

      &-tag {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #606669;
        letter-spacing: 0.015em;
        position: absolute;
        left: 0;
        top: 0;
        text-transform: uppercase;

        @include desktop {
          position: initial;
        }
      }

      &-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
        margin-bottom: 4px;
        margin-top: 0;

        @include desktop {
          margin-bottom: inherit;
        }
      }

      &-location {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: flex;

        @include desktop {
          position: absolute;
          bottom: 0;
          left: 0;
        }

        .icon-poi {
          font-size: 24px;
          margin-right: 4px;
        }
      }
    }

    &__price {
      position: relative;
      display: flex;
      padding-bottom: 16px;
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      width: calc(50% - 12px);
      height: 70px;

      @include desktop {
        width: initial;
        height: 80px;
        line-height: 20px;
        margin: 0 40px;
        flex-shrink: 0;
        justify-content: center;
      }

      &-tag {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        color: #888c8f;
      }

      &-content {
        align-self: flex-end;
        font-size: 14px;
        color: #155583;

        .c-price {
          min-width: 85px;
        }
      }

      &-number {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.0015em;
        color: #2f5a76;
        margin-bottom: 4px;
        display: inline-block;

        @include desktop {
          font-size: 36px;
          line-height: 44px;
          margin-bottom: initial;
        }
      }

      &-currency,
      &-type {
        align-self: flex-end;
      }

      &-currency {
        margin-bottom: 2px;
        position: relative;
        &::before {
          content: "*";
          position: absolute;
          top: 0;
          left: 100%;
          width: 20px;
          height: 20px;
          font-size: 12px;
          line-height: 27px;
        }

        @include desktop {
          margin-bottom: 0;
          &::before {
            content: "*";
            font-size: initial;
            line-height: initial;
          }
        }

        &::after {
          font-size: 12px;
          line-height: 30px;

          @include desktop {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }

      &-type {
        margin-left: 4px;
        margin-bottom: 8px;
        font-size: 11px;
        line-height: 16px;
        color: #8c9091;

        @include desktop {
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 20px;
        }
      }

      &-tax {
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0.004em;

        top: 0px;
        margin-top: 54px;
        line-height: 14px;

        @include desktop {
          top: 64px;
          bottom: initial;
          margin-top: 0;
          line-height: 16px;
          white-space: nowrap;
        }
      }
    }

    &__contact {
      width: calc(50% - 12px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 190px;
      @include desktop {
        width: 269px;
        flex-shrink: 0;
      }

      .c-cta {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 8px 16px;
        max-width: 220px;

        @include desktop {
          max-width: initial;
        }
      }
    }
  }

  &__gallery {
    display: none;

    @include desktop {
      display: flex;
      gap: 8px;
    }

    &__main {
      width: calc(50% - 4px);
      height: 461px;
      object-fit: cover;
    }

    &__group {
      width: calc(50% - 4px);
      display: flex;
      flex-wrap: wrap;
      height: 461px;
      align-content: flex-start;
      gap: 8px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include desktop {
        width: calc(50% - 4px);
        height: 226px;
      }
    }
  }

  &__swiper {
    height: 260px;

    @include desktop {
      display: none !important;
    }

    &.swiper {
      .swiper-pagination {
        &.swiper-pagination-bullets {
          bottom: 15px;

          .swiper-pagination-bullet {
            background-color: #fff;
            height: 6px;
            border-radius: 100px;
            margin: 0 4px;
            border: initial;
            &-active {
              width: 20px;
            }
          }
        }
      }
    }
  }

  &__services {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
    margin-bottom: 64px;

    @include desktop {
      margin-top: 64px;
    }

    &__title {
      width: 100%;

      .cmp-title__text {
        color: #0f1418;
      }
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      max-width: 716px;
      text-align: center;
      color: #757575;
      width: 100%;
      margin-top: 24px;
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 32px;
      margin: 40px 0;
      width: 100%;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 24px;
      }

      @include desktop {
        grid-template-columns: 1fr 1fr 1fr;
        margin: 48px 0;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-image {
        width: 100%;
        height: 175px;
        object-fit: cover;

        @include desktop {
          height: 271px;
        }
      }

      &-title {
        font-family: "Sfizia";
        font-style: italic;
        font-size: 28px;
        line-height: 32px;
        font-weight: 400;
        margin: 16px;

        @include desktop {
          font-size: 40px;
          line-height: 48px;
        }
      }

      &-content {
        margin: 0 16px;
      }

      &-category {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.005em;
        margin-bottom: 4px;

        @include desktop {
          margin-bottom: 8px;
        }
      }
    }

    &__contact {
      width: 280px;

      .c-cta {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.mod--hidden {
        display: none;
      }
    }
    &__terms {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-bottom: 56px;

      &-title {
        font-family: "Sfizia";
        font-style: italic;
        font-size: 28px;
        line-height: 32px;
        font-weight: 400;
        margin: 16px;

        @include desktop {
          font-size: 40px;
          line-height: 48px;
        }
      }

      &-content {
        margin: 0 16px;

        ul {
          list-style: unset;
          padding-left: 16px;
        }
      }
    }
  }

  &__hotels {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    max-width: 1036px;
    margin: 0 auto;
    .bwd-comparator-card {
      display: flex;
      flex-flow: column;
      align-content: flex-start;
      width: 100%;
      @include desktop {
        width: 31.75%;
      }
    }
    .bwd-comparator-card__swiper {
      // width: 320px;
      width: 100%;
    }
    .bwd-comparator-card__info {
      height: auto;
    }
    .bwd-comparator-card__info-country {
      position: relative;
      align-self: baseline;
      &:after {
        content: "";
        height: 1px;
        width: calc(100% - 28px);
        right: 0;
        bottom: 4px;
        display: block;
        position: absolute;
        background: #384044;
      }
    }
    .bwd-comparator-card__info-ratings__tag {
      flex: 1 0 auto;
    }
    .bwd-comparator-card__info-ratings__tripadvisor .ta__social {
      width: auto;
    }
    .bwd-comparator-card__info-button {
      display: flex;
      width: calc(100% - 16px);
      margin: auto auto 12px auto;
    }
    .bwd-comparator-card__info-highlights {
      margin-bottom: 0;
      height: 92px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $barcelo-black;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  &__message {
    border-radius: 8px;
    background-color: #f5f5f6;
    color: #384044;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.0025em;
    width: calc(100% - 16px);
    max-width: 1036px;
    margin: 48px auto 0 auto;
    .cmp-text {
      padding: 8px 12px;
    }
    b {
      font-weight: 400;
    }
    a {
      text-decoration: underline;
    }
  }
}
